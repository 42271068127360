import recommend from '@algolia/recommend';
import { getAppConfig } from '@utils/get-app-config';
import { RecommendService } from '../../domain/search/recommend-service';
import algoliaProductMapper from '@utils/mappers/product/algolia-product-mapper';
import { HitProps } from '@utils/format-product-data';
import { Product } from '@framework/types';

import { conversion_data, click_data, wishlist_data } from './domain/events';
import axios from 'axios';

const config = getAppConfig();
const recommendClient = recommend(config.ALGOLIA_APPID, config.ALGOLIA_APIKEY);
const index = config.ALGOLIA_INDEXNAME;

const params = {
  'X-Algolia-Application-Id': config.ALGOLIA_APPID,
  'X-Algolia-API-Key': config.ALGOLIA_APIKEY,
  'X-Algolia-Agent': 'insights-middleware',
};
const algolia_base_url = config.ALGOLIA_INSIGHTS_URL;

const AlgoliaRecommendGateway = (): RecommendService => {
  const getRelatedProducts = async (sku: string): Promise<Product[]> => {
    const recommendedItems = await recommendClient.getRelatedProducts([
      {
        indexName: index,
        objectID: sku,
      },
    ]);

    return algoliaProductMapper(recommendedItems.results[0].hits as HitProps[]);
  };
  const getUpsellProducts = async (sku: string): Promise<Product[]> => {
    const upsellProducts = await recommendClient.getFrequentlyBoughtTogether([
      {
        indexName: index,
        objectID: sku,
      },
    ]);
    return algoliaProductMapper(upsellProducts.results[0].hits as HitProps[]);
  };

  const getTrendingProducts = async (): Promise<Product[]> => {
    const trendingProducts = await recommendClient.getTrendingItems([
      {
        indexName: index,
      },
    ]);
    return algoliaProductMapper(trendingProducts.results[0].hits as HitProps[]);
  };

  const sendProductClick = (id: string, userToken: string) => {
    axios.post(algolia_base_url, click_data(index, id, userToken), { params });
    return null;
  };

  const sendProductConversion = (id: string, userToken: string) => {
    axios.post(algolia_base_url, conversion_data(index, id, userToken), {
      params,
    });
    return null;
  };

  const sendWishlistConversion = (id: string, userToken: string) => {
    axios.post(algolia_base_url, wishlist_data(index, id, userToken), {
      params,
    });
    return null;
  };

  return {
    getRelatedProducts,
    getUpsellProducts,
    getTrendingProducts,

    sendProductClick,
    sendProductConversion,
    sendWishlistConversion,
  };
};
export { AlgoliaRecommendGateway };
