export const LIMITS = {
  CATEGORIES_LIMITS: 6,
  PRODUCTS_LIMITS: 20,
  RELATED_PRODUCTS_LIMITS: 12,
  BEST_SELLER_PRODUCTS_LIMITS: 12,
  BEST_SELLER_HOME2_PRODUCTS_LIMITS: 6,
  NEW_ARRIVAL_PRODUCTS_LIMITS: 12,
  POPULAR_PRODUCTS_LIMITS: 12,
  POPULAR_PRODUCTS_TWO_LIMITS: 4,
  FASHION_PRODUCTS_LIMITS: 12,
  ELETRONIC_PRODUCTS_LIMITS: 12,
};
