import BannerCard from '@components/cards/banner-card';
import { HalfBannerResponse } from 'src/domain/cms/home/half-banner';

interface BannerProps {
  data: HalfBannerResponse;
  className?: string;
  dropDownGrid?: string;
}

export const enum GridType {
  Half = 'half',
  OneThird = 'oneThird',
}

const getGridClass = (type: string) => {
  if (type === GridType.Half) {
    return 'xl:gap-5';
  }

  if (type === GridType.OneThird) {
    return 'xl:gap-5 2xl:grid-cols-[minmax(1130px,_1fr)_1fr] ';
  }

  return '2xl:gap-5';
};

const BannerGridTwo: React.FC<BannerProps> = ({
  data,
  className = 'mb-3 md:mb-4 lg:mb-5 xl:mb-6',
}) => {
  const banners = data?.banners || [];

  return (
    <div className={className}>
      <div
        className={`${getGridClass(
          data?.gridType,
        )} grid gap-4 grid-cols-1 lg:grid-cols-2 `}
      >
        {banners?.map((banner: any, index: number) => (
          <BannerCard
            key={`banner--key${index}`}
            banner={banner}
            effectActive={true}
          />
        ))}
      </div>
    </div>
  );
};

export default BannerGridTwo;
