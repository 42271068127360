import CategoryProductsGrid from '@components/common/categoryProductsGrid/category-products-grid';
import ProductsCarousel from '@components/product/products-carousel';
import ProductController from 'src/controller/product-controller';
import { ROUTES } from '@utils/routes';

interface CategoryProductsProps {
  data: {
    fields: {
      categoryId: string;
      showAsCarousel: boolean;
      title: string;
      maxProduct: number;
    };
  };
}

const CategoryProducts = ({ data }: CategoryProductsProps) => {
  const { categoryId, showAsCarousel, title, maxProduct } = data?.fields ?? {};
  const hitsPerPage = maxProduct < 1 ? 20 : maxProduct;

  if (!categoryId) return null;
  const categorySlug = ROUTES.CATEGORIES + '/' + categoryId;
  const {
    data: products,
    isLoading,
    error,
  } = ProductController.ProductByCategory.use({
    category: categoryId,
    hitsPerPage,
    enabled: showAsCarousel,
  });

  if (showAsCarousel && (isLoading || !products)) return null;

  return (
    <>
      {showAsCarousel ? (
        <ProductsCarousel
          sectionHeading={title}
          showHeading={Boolean(title)}
          categorySlug={categorySlug}
          products={products ?? []}
          loading={isLoading}
          error={error?.message}
          limit={hitsPerPage}
          uniqueKey="commerce-products"
          className="mb-8"
        />
      ) : (
        <CategoryProductsGrid
          title={title}
          categoryId={categoryId}
          hitsPerPage={hitsPerPage}
        />
      )}
    </>
  );
};

export default CategoryProducts;
