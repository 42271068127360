import { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';
import useUiStore from 'src/stores/uiStore';
import { siteSettings } from '@settings/site-settings';
import { AddActiveScroll } from '@utils/add-active-scroll';
import Container from '@components/ui/container';
import Logo from '@components/ui/logo';
import HeaderMenu from '@components/layout/header/header-menu';
import Search from '@components/common/search-algolia';
import LanguageSwitcher from '@components/ui/language-switcher';
import UserIcon from '@components/icons/user-icon';
import SearchIcon from '@components/icons/search-icon';
import useOnClickOutside from '@utils/use-click-outside';
import { FiMenu } from 'react-icons/fi';
import CategoryDropdownMenu from '@components/category/category-dropdown-menu';
import { useRouter } from 'next/router';
import headerController from 'src/controller/header-controller';
import { LOCALES } from '@utils/constants/locales';
import Link from '@components/ui/link';
const AuthMenu = dynamic(() => import('./auth-menu'), { ssr: false });
const CartButton = dynamic(() => import('@components/cart/cart-button'), {
  ssr: false,
});

type DivElementRef = React.MutableRefObject<HTMLDivElement>;
const { site_header } = siteSettings;

const Header = ({ headerData, categoryData }: any) => {
  const { t } = useTranslation('menu');
  const router = useRouter();
  const { locale } = router;
  const localeParam = locale ? LOCALES[locale.toUpperCase()] : LOCALES.DEFAULT;
  const headerContent = headerController.cmsHeader.use({
    locale: localeParam,
    enabled: !headerData?.logo,
  });
  const categoryContent = headerController.categoryMenu.use({
    limit: 15,
    enabled: !categoryData,
  });
  const headerCMSContent = headerData?.logo ? headerData : headerContent.data;
  const headerLoading = headerData?.logo ? false : headerContent.isLoading;
  const allCategories = categoryData ?? categoryContent.data;
  const allCategoriesLoading = categoryData ? false : categoryContent.isLoading;
  const {
    displayMobileSearch,
    closeSearch,
    isAuthorized,
    toggleMobileSearch,
    setHeaderData,
    setCategoryData,
  } = useUiStore();
  const siteHeaderRef = useRef() as DivElementRef;
  const siteSearchRef = useRef() as DivElementRef;
  const [categoryMenu, setCategoryMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string>('');
  AddActiveScroll(siteHeaderRef, 130);
  useOnClickOutside(siteSearchRef, () => closeSearch());

  function handleCategoryMenu() {
    setCategoryMenu(!categoryMenu);
  }

  useEffect(() => {
    setHeaderData(headerCMSContent);
    setCategoryData(allCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerLoading]);

  // close the menu on menu click
  useEffect(() => {
    const handleRouteChange = () => {
      setActiveMenu('');
      setCategoryMenu(false);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
  }, [router.events]);

  return (
    <>
      <header
        id="siteHeader"
        ref={siteHeaderRef}
        className={cn(
          'header-five sticky-header sticky top-0 z-50 lg:relative w-full',
          displayMobileSearch && 'active-mobile-search',
        )}
      >
        <div className="bg-skin-footer innerSticky w-screen lg:w-full transition-all duration-200 ease-in-out body-font z-50">
          <Search
            searchId="mobile-search"
            className="top-bar-search hidden lg:max-w-[600px] absolute z-30 px-4 md:px-6 top-12 xl:top-1"
          />
          {/* End of Mobile search */}

          <div className="top-bar text-13px text-gray-300 border-b border-white/5 hidden lg:block">
            <Container>
              <div className="h-12 flex justify-between items-center py-2 gap-5">
                {headerLoading ? (
                  <p>loading...</p>
                ) : (
                  <div className={`hidden md:block truncate`}>
                    {headerCMSContent?.usp}
                  </div>
                )}
                <div className="flex flex-shrink-0 smx-auto max-w-[1920px]pace-s-5">
                  {/* <HeaderMenutop
                    data={site_header.topmenu}
                    className="flex transition-all duration-200 ease-in-out"
                  /> */}
                  <div className="menuItem group cursor-pointer mx-2 md:mx-3">
                    <Link
                      href="/store-finder"
                      className="text-gray-300 inline-flex items-center py-2 font-normal relative group-hover:text-skin-primary"
                    >
                      {t('menu-store-finder')}
                    </Link>
                  </div>
                  <div className="menuItem group cursor-pointer mx-2 md:mx-3">
                    <Link
                      href="/quick-order"
                      className="text-gray-300 inline-flex items-center py-2 font-normal relative group-hover:text-skin-primary"
                    >
                      {t('menu-quick-order')}
                    </Link>
                  </div>
                  {!isAuthorized && (
                    <div className="menuItem group cursor-pointer mx-2 md:mx-3">
                      <Link
                        href="/register"
                        className="text-gray-300 inline-flex items-center py-2 font-normal relative group-hover:text-skin-primary"
                      >
                        {t('menu-register')}
                      </Link>
                    </div>
                  )}

                  <LanguageSwitcher />
                </div>
              </div>
            </Container>
          </div>
          <div className="border-b border-white/5">
            <Container>
              <div className="flex items-center justify-center lg:justify-between py-2 md:py-4 gap-3">
                <Logo
                  image={headerCMSContent?.logo}
                  isLoading={headerLoading}
                  className="logo lg:mx-0"
                />
                {/* End of logo */}

                <div className="absolute right-4 md:right-6 flex-shrink-0 lg:hidden">
                  <LanguageSwitcher hideLabel={true} />
                </div>

                <Search
                  searchId="top-bar-search"
                  className="hidden lg:flex lg:max-w-[450px] xl:max-w-[650px] 2xl:max-w-[900px] lg:mx-10"
                  variant="fill"
                />
                {/* End of search */}

                <div className="flex space-s-5 xl:space-s-10 lg:max-w-[33%]">
                  <div className="hidden lg:flex items-center flex-shrink-0">
                    <div className="cart-button">
                      <UserIcon className="text-skin-primary" />
                    </div>

                    <AuthMenu
                      isAuthorized={isAuthorized}
                      labelClasses="text-sm text-white font-normal focus:outline-none ms-2 truncate max-w-[90px]"
                    />
                  </div>
                  <CartButton className="hidden lg:flex" />
                </div>
                {/* End of auth & lang */}
              </div>
            </Container>
          </div>
          {/* End of top part */}

          <div className="navbar hidden lg:block bg-skin-footer">
            <Container>
              <div className="flex justify-between items-center">
                <Logo
                  image={headerCMSContent?.logo}
                  isLoading={headerLoading}
                  className="navbar-logo w-0 opacity-0 transition-all duration-200 ease-in-out"
                />
                {/* End of logo */}
                <div className="categories-header-button relative me-8 flex-shrink-0 w-72">
                  <button
                    className="bg-skin-primary rounded-t min-h-[60px] focus:outline-none w-full font-medium px-[18px] uppercase py-4 flex items-center transition-all hover:border-skin-four"
                    onClick={handleCategoryMenu}
                  >
                    <FiMenu className="text-2xl me-3" />
                    {t('text-all-categories')}
                  </button>
                  {categoryMenu && (
                    <CategoryDropdownMenu
                      data={allCategories}
                      isLoading={allCategoriesLoading}
                      error={null}
                      className=""
                    />
                  )}
                </div>

                <HeaderMenu
                  data={headerCMSContent?.navigation}
                  isLoading={headerLoading}
                  className="flex transition-all duration-200 ease-in-out"
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                />
                {/* End of main menu */}

                <div className="ms-auto flex items-center flex-shrink-0">
                  <div className="navbar-right flex items-center overflow-hidden w-0 opacity-0 transition-all duration-200 ease-in-out">
                    <button
                      type="button"
                      aria-label="Search Toggle"
                      onClick={toggleMobileSearch}
                      title="Search toggle"
                      className="outline-none 1.5xl:me-6 w-12 md:w-14 h-full flex items-center justify-center transition duration-200 ease-in-out hover:text-heading focus:outline-none"
                    >
                      <SearchIcon className="w-[22px] h-[22px] text-white " />
                    </button>
                    {/* End of search handler btn */}

                    <div className="flex-shrink-0 flex items-center">
                      <div className={'cart-button'}>
                        <UserIcon className="text-skin-primary" />
                      </div>

                      <AuthMenu isAuthorized={isAuthorized} />
                    </div>
                    {/* End of auth */}

                    <CartButton className="ms-8 " />
                    {/* End of cart btn */}
                  </div>
                </div>
              </div>
            </Container>
          </div>
          {/* End of menu part */}
        </div>
      </header>
      {categoryMenu && (
        <button
          className="shadow_bkg_show fixed w-full h-full inset-0 bg-black/60 z-40"
          onClick={handleCategoryMenu}
          onKeyDown={handleCategoryMenu}
        />
      )}
    </>
  );
};

export default Header;
