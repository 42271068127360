import Heading from '@components/ui/heading';

const Panel = ({
  children,
  header,
}: {
  children: React.ReactNode;
  header?: React.ReactNode;
}) => {
  return (
    <div className="block">
      {header && (
        <Heading className="lg:text-xl mb-5 -mt-1 block-title">
          {header}
        </Heading>
      )}
      <div className="flex flex-col">{children}</div>
    </div>
  );
};

export default Panel;
