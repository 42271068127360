import HeroTwoSliderBlock from '@components/hero/hero-slider-block';
import FeatureCarousel from '@components/common/featured-carousel';
import ProductCarouselFeed from '@components/product/feeds/product-carousel-feed';
import BannerGridTwo from '@components/common/banner-grid-two';
import BannerAllCarousel from '@components/common/banner-all-carousel';
import PopularCategorylist from '@components/product/feeds/popular-categorylist';
import CategoryGridListBlock from '@components/common/category-grid-list-block';
import BlogCmsComponent from '@components/common/blog-cms-component';
import FreeStyleHTML from '@components/common/freeStylehtml/free-style-html';
import FaqWrapper from '@components/common/faq/faq';
import ContactUsWrapper from '@components/common/contactUs/contact-us';
import CategoryProducts from '@components/common/categoryProducts/category-products';
import ContentTileWrapper from '@components/common/content-tile-component';
import { mapProductCarousel } from './mappers/home/product-carousel-mapper';
import { mapHomeHeroBanner } from './mappers/home/hero-banner-mapper';
import { mapHomeHalfBanner } from './mappers/home/half-banner-mapper';
import { mapHomeUSP } from './mappers/home/usp-mapper';
import { mapShopByCategoriesWrapper } from './mappers/home/shop-by-categories-mapper';
import { mapHomeBrandSearch } from './mappers/home/brand-search-mapper';
import { mapPopularCategoryList } from './mappers/home/popular-category-list-mapper';
import { getAppConfig } from '@utils/get-app-config';

export type ContentBlockProps = {
  fields: {
    title: string;
    [key: string]: any;
  };
  sys: {
    contentType: {
      sys: {
        id: string;
      };
    };
  };
  isLoading?: boolean;
  error?: Error | null;
};

export type AvailableComponents =
  | 'HeroTwoSliderBlock'
  | 'FeatureCarousel'
  | 'ProductCarouselFeed'
  | 'BannerGridTwo'
  | 'BannerAllCarousel'
  | 'PopularCategorylist'
  | 'CategoryGridListBlock'
  | 'BlogCmsComponent'
  | 'FreeStyleHTML'
  | 'FaqWrapper'
  | 'ContactUsWrapper'
  | 'CategoryProducts'
  | 'ContentTileWrapper';

export const ComponentNameMapper = {
  HeroTwoSliderBlock,
  FeatureCarousel,
  ProductCarouselFeed,
  BannerGridTwo,
  BannerAllCarousel,
  PopularCategorylist,
  CategoryGridListBlock,
  BlogCmsComponent,
  FreeStyleHTML,
  FaqWrapper,
  ContactUsWrapper,
  CategoryProducts,
  ContentTileWrapper,
};

// contentful component id mapping
export const ComponentType = {
  HeroBanner: 'heroBannerCarousel',
  UspWrapper: 'uspWrapper',
  HalfBanner: 'halfBanner',
  BestSellers: 'productCarouselWrapper',
  Digital: 'popularCategoryListWrapper',
  Fashion: 'popularCategoryListWrapper',
  BannerAllImageCarousel: 'brandCarousal',
  Categories: 'categories',
  LatestblogCarousel: 'blogCarousal',
  FreeStyleHTML: 'freeStyleHtml',
  FaqWrapper: 'faqWrapper',
  ContactUs: 'contactUs',
  CategoryProducts: 'productsListFromSpecificCategory',
  ContentTileWrapper: 'contentTileWrapper',
};

// TO_DO: we can remove the hardcoded props and put that logic/setting in contentful
export const getComponentProps = (
  props: ContentBlockProps,
): [AvailableComponents, any] | null => {
  const config = getAppConfig();
  const type = props?.sys?.contentType?.sys?.id;

  if (type === ComponentType.HeroBanner) {
    return [
      'HeroTwoSliderBlock',
      {
        data: mapHomeHeroBanner(props),
        className: `mb-7 mt-6`,
        contentClassName: 'p-5 sm:pb-24 xl:pb-32 sm:pt-16 xl:pt-24',
        ...props,
      },
    ];
  }
  if (type === ComponentType.UspWrapper) {
    return [
      'FeatureCarousel',
      {
        data: mapHomeUSP(props),
        ...props,
      },
    ];
  }
  if (type === ComponentType.HalfBanner) {
    return [
      'BannerGridTwo',
      {
        data: mapHomeHalfBanner(props),
        className: 'mb-8 lg:mb-12',
        ...props,
      },
    ];
  }
  if (type === ComponentType.BestSellers) {
    return [
      'ProductCarouselFeed',
      {
        data: mapProductCarousel(props),
        algoliaFetchDisable: config.DISABLE_ALGOLIA_FETCH === 'true',
        ...props,
      },
    ];
  }
  if (type === ComponentType.Digital) {
    return [
      'PopularCategorylist',
      {
        data: mapPopularCategoryList(props),
        ...props,
      },
    ];
  }
  if (type === ComponentType.Fashion) {
    return [
      'PopularCategorylist',
      {
        data: mapPopularCategoryList(props),
        ...props,
      },
    ];
  }
  if (type === ComponentType.Categories) {
    return [
      'CategoryGridListBlock',
      {
        data: mapShopByCategoriesWrapper(props),
        ...props,
      },
    ];
  }
  if (type === ComponentType.BannerAllImageCarousel) {
    return [
      'BannerAllCarousel',
      {
        data: mapHomeBrandSearch(props),
        ...props,
      },
    ];
  }
  if (type === ComponentType.LatestblogCarousel) {
    return [
      'BlogCmsComponent',
      {
        data: props,
        ...props,
      },
    ];
  }
  if (type === ComponentType.FreeStyleHTML) {
    return [
      'FreeStyleHTML',
      {
        data: props,
        ...props,
      },
    ];
  }
  if (type === ComponentType.FaqWrapper) {
    return [
      'FaqWrapper',
      {
        data: props,
        ...props,
      },
    ];
  }
  if (type === ComponentType.ContactUs) {
    return [
      'ContactUsWrapper',
      {
        data: props,
        ...props,
      },
    ];
  }
  if (type === ComponentType.CategoryProducts) {
    return [
      'CategoryProducts',
      {
        data: props,
        ...props,
      },
    ];
  }
  if (type === ComponentType.ContentTileWrapper) {
    return [
      'ContentTileWrapper',
      {
        data: props,
        ...props,
      },
    ];
  }
  return null;
};
