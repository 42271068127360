import Heading from '@components/ui/heading';
import Image from '@components/ui/image';
import Text from '@components/ui/text';
import { useTranslation } from 'next-i18next';
import { collectionPlaceholder } from '@assets/placeholders';

interface Props {
  imgWidth?: number | `${number}`;
  imgHeight?: number | `${number}`;
  collection: any;
}

const ContentCardData: React.FC<Props> = ({
  collection,
  imgWidth = 440,
  imgHeight = 280,
}) => {
  const { image, title, readMore, shortDescription } = collection;
  const { t } = useTranslation('common');
  return (
    <>
      <Image
        src={`https:${image?.fields?.file?.url}` ?? collectionPlaceholder}
        alt={title || t('text-card-thumbnail')}
        width={imgWidth}
        height={imgHeight}
        className="overflow-hidden  bg-skin-thumbnail object-cover transform transition duration-300 ease-in-out group-hover:opacity-90 "
      />
      <div className="flex flex-col mt-4 mb-6 ">
        {!!title && (
          <Heading
            variant="title"
            className="mb-2 lg:mb-4 text-base text-[24px] xl:text-[24px] group-hover:text-skin-primary"
          >
            {title}
          </Heading>
        )}
        {!!shortDescription && (
          <div className="mb-1 lg:mb-1.5 text-base text-purple-800 text-[14px] xl:text-[16px]">
            {shortDescription}
          </div>
        )}
        {!!readMore && (
          <Text
            variant="body"
            className="text-[22px] sm:text-13px mb-10 uppercase group-hover:underline"
          >
            {'Read more >>'}
          </Text>
        )}
      </div>
    </>
  );
};

export default ContentCardData;
