import FaqAccordion from '@components/ui/faq-accordion';

type FAQProps = {
  data: {
    fields: {
      title?: string;
      items?: any[];
    };
  };
};

const FaqWrapper = ({ data }: FAQProps) => {
  const faq = data?.fields?.items ?? null;

  if (!faq) return null;

  return (
    <div className="flex flex-col max-w-2xl xl:max-w-4xl mx-auto py-6">
      <h2 className="text-2xl md:text-4xl font-medium mb-3 md:mb-4 xl:mb-3 -mt-2 xl:-mt-3 2xl:-mt-4">
        {data?.fields?.title}
      </h2>
      {faq?.map((item, index) => (
        <FaqAccordion
          key={`${item?.fields?.title}-${index}`}
          item={item?.fields}
          translatorNS="faq"
        />
      ))}
    </div>
  );
};

export default FaqWrapper;
