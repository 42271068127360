import cn from 'classnames';
import Image from '@components/ui/image';
import Link from '@components/ui/link';
import { getPrice } from '@framework/product/use-price';
import { Product } from '@framework/types';
import { AddToCart } from '@components/product/add-to-cart';
import { useTranslation } from 'next-i18next';
import { productPlaceholder } from '@assets/placeholders';
import { ROUTES } from '@utils/routes';

import { sendProductClick } from '../../../use-cases/send-product-click';
import { AlgoliaRecommendGateway } from '../../../gateway/algolia/algolia-recommend-gateway';
import Cookies from 'js-cookie';

interface ProductProps {
  product: Product;
  className?: string;
}
function RenderPDPLinkOrAddToCart({ product }: { readonly product: Product }) {
  const { t } = useTranslation('common');
  const { variations } = product ?? {};
  if (variations?.length) {
    return (
      <Link
        className={
          'min-w-[150px] px-4 py-2 bg-skin-primary text-skin-inverted text-[13px] items-center justify-center focus:outline-none focus-visible:outline-none rounded-full'
        }
        href={`${ROUTES.PRODUCTS}/${product.slug}`}
      >
        {t('text-view')}
      </Link>
    );
  }
  return <AddToCart product={product} />;
}
const ProductList: React.FC<ProductProps> = ({ product, className }) => {
  const { name, image, unit, slug, description, sku, variations } =
    product ?? {};
  const { t } = useTranslation('common');
  const userTokenCookie = Cookies.get('userToken');
  const userToken = userTokenCookie ?? 'test_token';
  const basePrice = null;

  const variation_text = variations?.length
    ? variations.length + ' ' + t('text-variations')
    : '';
  const formatted_price = getPrice(product);

  return (
    <article
      className={cn(
        ' product-list-view card-image--jump  overflow-hidden relative  grid grid-cols-4  gap-8',
        className,
      )}
      title={name}
    >
      <div className="col-span-1 ">
        <Link
          href={`${ROUTES.PRODUCTS}/${slug}`}
          className="flex justify-center items-center h-full border border-skin-base hover:border-skin-primary"
        >
          <div className="max-w-[270px] mx-auto relative ">
            <Image
              src={image?.thumbnail ?? productPlaceholder}
              alt={name || 'Product Image'}
              width={270}
              height={270}
              quality={100}
              className="object-cover bg-skin-thumbnail"
            />
          </div>
        </Link>
      </div>
      <div className="col-span-3">
        <div className="text-12px sm:text-sm mt-auto text-gray-400 mb-2">
          {unit}
        </div>
        <Link
          onClick={() =>
            sendProductClick(AlgoliaRecommendGateway(), sku!, userToken)
          }
          href={`${ROUTES.PRODUCTS}/${slug}`}
          className="text-skin-base text-base font-semibold leading-5 min-h-[30px] line-clamp-2 mb-1.5 hover:text-skin-primary"
        >
          {name}
        </Link>

        <div className="space-s-2 mb-2">
          <span className="inline-block font-semibold text-sm sm:text-15px lg:text-base text-skin-primary">
            {formatted_price}
          </span>
          {basePrice && (
            <del className="text-sm text-gray-400 text-opacity-70">
              {basePrice}
            </del>
          )}
        </div>
        <p className="text-sm text-skin-base line-clamp-3 leading-6 text-opacity-80">
          {description}
        </p>
        <p className="text-sm text-skin-base line-clamp-3 leading-6 text-opacity-80">
          {variation_text}
        </p>
        <div className="inline-block product-cart-button mt-6">
          <RenderPDPLinkOrAddToCart product={product} />
        </div>
      </div>
    </article>
  );
};

export default ProductList;
