import { CategoryType } from 'src/domain/cms/home/shop-by-category';

export function mapPopularCategoryList(apiData: any): CategoryType {
  const data = mapPopularCategory(
    apiData?.fields?.category,
    apiData?.fields?.productsList,
  );

  return data;
}

function mapPopularCategory(apiCategory: any, productsList: any): CategoryType {
  const newCategoryImage = apiCategory?.fields?.image?.fields;
  const { subCategories } = apiCategory.fields;
  const mappedSubCategories = mapPopularSubCategories(subCategories);
  const mappedCategories: CategoryType = {
    image: {
      desktop: {
        url: `https:${newCategoryImage?.desktopImage?.fields?.file?.url}`,
      },
    },
    name: apiCategory?.fields?.name,
    slug: apiCategory?.fields?.categorySlug,
    key: apiCategory?.fields?.categorySlug,
    subCategories: mappedSubCategories,
    commerceProductCarousel: productsList?.fields,
  };
  return mappedCategories;
}

function mapPopularSubCategories(apiSubCategories: any): CategoryType[] {
  const mappedSubCategories: CategoryType[] = new Array();

  apiSubCategories?.forEach((apiSubCategory: any) => {
    const newSubCategory: CategoryType = {
      name: apiSubCategory?.fields?.name,
      slug: apiSubCategory?.fields?.categorySlug,
      key: apiSubCategory?.fields?.categorySlug,
    };
    mappedSubCategories.push(newSubCategory);
  });

  return mappedSubCategories;
}
