import Link from '@components/ui/link';
import { LinkProps } from 'next/link';

import ContentCardData from './content-card-data';

interface Props {
  href: LinkProps['href'];
  collection: any;
}

const ContentCard: React.FC<Props> = ({ collection, href }) => {
  return (
    <>
      {href !== 'undefined' ? (
        <Link href={href} className="group flex flex-col ">
          <ContentCardData collection={collection}></ContentCardData>
        </Link>
      ) : (
        <ContentCardData collection={collection}></ContentCardData>
      )}
    </>
  );
};

export default ContentCard;
