import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from '@components/ui/link';
import {
  IoIosArrowForward,
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
  IoIosArrowBack,
} from 'react-icons/io';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { getDirection } from '@utils/get-direction';
import { ISHCategoryType } from 'src/domain/commerce/category/category-menu';

interface SidebarMenuItemProps {
  readonly className?: string;
  readonly item: ISHCategoryType;
  readonly depth?: number;
}

function SidebarMenuItem({ className, item, depth = 0 }: SidebarMenuItemProps) {
  const { locale } = useRouter();
  const dir = getDirection(locale);
  const { name, slug, subCategories: items } = item;

  return (
    <>
      <li
        className={`px-4  transition relative ${
          className ?? 'text-sm hover:text-skin-primary '
        }`}
      >
        <Link
          href={slug}
          className={cn(
            'flex items-center w-full py-3 text-start outline-none focus:outline-none focus:ring-0 focus:text-skin-base',
          )}
        >
          <span className="capitalize ">{name}</span>
          {items && (
            <span className="ms-auto hidden md:inline-flex">
              {dir === 'rtl' ? (
                <IoIosArrowBack className="text-15px text-skin-base text-opacity-40" />
              ) : (
                <IoIosArrowForward className="text-15px text-skin-base text-opacity-40" />
              )}
            </span>
          )}
        </Link>
        {Array.isArray(items) && (
          <>
            {
              <div
                className={`dropdownMenu hidden md:block absolute z-10 left-full top-0 w-64 bg-skin-fill opacity-0 invisible shadow-md subMenu--level${depth}`}
              >
                <ul key="content" className="text-xs px-1 py-3">
                  {items?.map((currentItem) => {
                    const childDepth = depth + 1;
                    return (
                      <SidebarMenuItem
                        key={`${currentItem.name}${currentItem.slug}`}
                        item={currentItem}
                        depth={childDepth}
                        className={cn(
                          'text-sm p-0 pe-4 text-skin-base hover:text-skin-primary mb-0.5',
                        )}
                      />
                    );
                  })}
                </ul>
              </div>
            }
          </>
        )}
      </li>
    </>
  );
}

interface CategoryMenuProps {
  readonly items: ISHCategoryType[] | undefined;
  readonly categoriesLimit: number;
  readonly className?: string;
}

function CategoryMenu({
  items,
  categoriesLimit,
  className,
}: CategoryMenuProps) {
  const [categoryMenuToggle, setcategoryMenuToggle] = useState(Boolean(false));
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const dir = getDirection(locale);

  function handleCategoryMenu() {
    setcategoryMenuToggle(!categoryMenuToggle);
  }

  return (
    <ul
      className={cn(
        'w-full bg-skin-fill border-t-0 border-2 border-skin-primary rounded-b-md category-dropdown-menu',
        className,
      )}
    >
      {items?.map((item: ISHCategoryType, idx: number) =>
        idx <= categoriesLimit - 1 ? (
          <SidebarMenuItem key={`${item.slug}-key-${item.key}`} item={item} />
        ) : (
          categoryMenuToggle && (
            <SidebarMenuItem key={`${item.slug}-key-${item.key}`} item={item} />
          )
        ),
      )}

      {items && items.length >= categoriesLimit && (
        <li
          className={`px-4 relative transition text-sm hover:text-skin-primary`}
        >
          <div
            className={`flex items-center w-full py-3 text-start cursor-pointer`}
            onClick={handleCategoryMenu}
          >
            <div
              className={`inline-flex flex-shrink-0 ${
                dir === 'rtl' ? 'ml-2' : 'mr-2'
              }`}
            >
              {categoryMenuToggle ? (
                <IoIosRemoveCircleOutline className="text-xl text-skin-base text-opacity-80" />
              ) : (
                <IoIosAddCircleOutline className="text-xl text-skin-base text-opacity-80" />
              )}
            </div>
            <span className="capitalize ">{t('text-all-categories')}</span>
          </div>
        </li>
      )}
    </ul>
  );
}

export default CategoryMenu;
