export const enum AlogliaStateEnum {
  Loading = 'loading',
  Stalled = 'stalled',
  Error = 'error',
  Idle = 'idle',
}

export const enum AlgoliaAttributes {
  Price = 'price',
  Rating = 'rating',
  FreeShipping = 'free_shipping',
}

export const enum ContentfulContentTypes {
  ProductCarouselWrapper = 'productCarouselWrapper',
}

export const enum KEYS {
  ENTER = 'Enter',
}
