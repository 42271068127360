import ContentCard from '@components/cards/content-card';
import Heading from '@components/ui/heading';

type Props = {
  data: {
    fields: {
      items: any;
      title?: string;
      tilesPerRow?: number;
      spacing?: number;
      margin?: number;
    };
  };
};

const ContentTileWrapper = ({ data }: Props) => {
  const { fields } = data;
  const items = fields?.items ?? null;
  const tilesPerRow = fields?.tilesPerRow ?? 2;
  const spacing = fields?.spacing ?? 12;
  const margin = fields?.margin ?? 0;
  const title = fields?.title ?? null;
  if (!items) return null;

  return (
    <>
      {!!title && (
        <Heading
          variant="title"
          className="mb-1 lg:mb-1.5 text-base text-[26px] xl:text-[32px] group-hover:text-skin-primary"
        >
          {title}
        </Heading>
      )}
      <div
        className={`mt-6 xl:mx-${margin} grid sm:grid-cols-${tilesPerRow} sm:space-x-${spacing}`}
      >
        {items?.map((item: any, idd: number) => (
          <div className="content-card" key={`content-tile-key-${idd}`}>
            <ContentCard
              collection={item?.fields}
              href={`${item?.fields?.link}`}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ContentTileWrapper;
