import Container from '@components/ui/container';
import Image from '@components/ui/image';
import cn from 'classnames';
import { CmsCopyright } from 'src/domain/cms/footer/footer';
import { PaymentOption } from 'src/domain/cms/footer/payment-options';

interface CopyrightProps {
  copyright?: CmsCopyright;
  payment?: PaymentOption[];
  className?: string;
}

const Copyright: React.FC<CopyrightProps> = ({
  copyright,
  payment,
  className = '',
}) => {
  return (
    <div
      className={cn(
        'border-t border-white/10  pt-5 pb-16 sm:pb-20 md:pb-5 mb-2 sm:mb-0',
        className,
      )}
    >
      <Container>
        <div className="flex flex-col md:flex-row text-center md:justify-between">
          <p className="text-gray-400 text-sm leading-7 lg:leading-[27px]">
            {copyright?.copyright}&nbsp;
            <span className="text-skin-primary transition-colors duration-200 ease-in-out hover:text-skin-primary">
              {copyright?.copyrightAuthorName}
            </span>
            &nbsp;{copyright?.allRightsReserved}
          </p>

          {payment && (
            <ul className="flex flex-wrap justify-center items-center space-s-4 -mb-1.5 md:mb-0 mx-auto md:mx-0 pt-3.5 md:pt-0">
              {payment?.map((item) => (
                <li
                  className="mb-2 md:mb-0 transition hover:opacity-80 inline-flex"
                  key={`payment-list--key${item.title}`}
                >
                  <span className="inline-flex">
                    <Image
                      src={item.image.desktop.url}
                      alt={item.title}
                      height={item.image.desktop.height}
                      width={item.image.desktop.width}
                    />
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Copyright;
