import { Header } from 'src/domain/cms/header/header';
import { mapNavigation } from './navigation-mapper';
import { mapBanner, mapImageType } from '../common/common-mapper';
import { DefaultSearchContent } from 'src/domain/cms/header/default-search-content';

export function mapHeader(apiData: any): Header {
  return {
    usp: apiData.fields.usp,
    logo: mapImageType(apiData.fields.logo),
    navigation: mapNavigation(apiData),
    notificationBar: apiData?.fields?.notificationBar,
  };
}

export function mapDefaultSearchContent(apiData: any): DefaultSearchContent {
  return {
    title: apiData.fields.title,
    promotionBanner: mapBanner(apiData.fields.promotionBanner),
  };
}
