import { PaymentOption } from 'src/domain/cms/footer/payment-options';
import { mapImageType } from '../common/common-mapper';

export function mapPaymentOptions(apiData: any): PaymentOption[] {
  const result: PaymentOption[] = new Array();
  const paymentOptions =
    apiData?.fields?.footerCopyRight[0].fields.paymentOptions?.fields
      ?.paymentOptions ?? [];

  paymentOptions.forEach((element: any) => {
    const title = element.fields.name;

    const paymentOption: PaymentOption = {
      title: title,
      image: mapImageType(element),
    };
    result.push(paymentOption);
  });

  return result;
}
