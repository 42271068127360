import BannerCard from '@components/cards/banner-card';
import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from 'swiper/react';

const breakpoints = {
  '1536': {
    slidesPerView: 7,
    spaceBetween: 20,
  },
  '1280': {
    slidesPerView: 5,
    spaceBetween: 16,
  },
  '1024': {
    slidesPerView: 4,
    spaceBetween: 16,
  },
  '768': {
    slidesPerView: 3,
    spaceBetween: 16,
  },
  '520': {
    slidesPerView: 2,
    spaceBetween: 12,
  },
  '0': {
    slidesPerView: 2,
  },
};

interface BannerProps {
  data: any;
  isLoading: boolean;
  className?: string;
  layout?: string;
  buttonSize?: 'default' | 'small';
}

const BannerAllCarousel: React.FC<BannerProps> = ({
  data,
  isLoading = true,
  className = 'mb-6',
  layout,
  buttonSize = 'default',
}) => {
  const classCarousel =
    'rounded border border-black/10  py-5  md:py-5 bg-white';
  return (
    <div className={className}>
      <div>
        {isLoading ? (
          <p>loading...</p>
        ) : (
          <Carousel
            autoplay={false}
            breakpoints={breakpoints}
            buttonSize={buttonSize}
            prevActivateId="all-banner-carousel-button-prev"
            nextActivateId="all-banner-carousel-button-next"
            className={classCarousel}
          >
            {data?.map((banner: any, index: number) => (
              <SwiperSlide key={`all-banner--key${index}`}>
                <BannerCard banner={banner} effectActive={true} />
              </SwiperSlide>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default BannerAllCarousel;
