import dynamic from 'next/dynamic';
import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import MobileNavigation from '@components/layout/mobile-navigation/mobile-navigation';
import DraftModeNotification from '@components/common/draft-mode-notification';
const BackToTopButton = dynamic(() => import('@components/ui/back-to-top'), {
  ssr: false,
});
const HighlightedBarContainer = dynamic(
  () => import('@components/common/higlighted-bar-container'),
);

const Layout: React.FC<React.PropsWithChildren & { pageProps: any }> = ({
  children,
  pageProps,
}) => {
  const { headerData, footerData, categoryData, draftMode } = pageProps;
  return (
    <div className="flex flex-col min-h-screen">
      {draftMode && <DraftModeNotification />}
      <HighlightedBarContainer data={headerData?.notificationBar} />
      {/* End of highlighted bar  */}

      <Header headerData={headerData} categoryData={categoryData} />
      <main
        className="relative flex-grow"
        style={{
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {children}
      </main>
      <Footer footerData={footerData} />
      <BackToTopButton />
      <MobileNavigation />
    </div>
  );
};

export default Layout;
