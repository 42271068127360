import { ToggleRefinement } from 'react-instantsearch';
import AlgoliaRefinementList from '@components/algolia/algolia-refinement-list';
import Panel from '@components/algolia/panel';
import PriceSlider from '@components/algolia/price-slider';
import Ratings from '@components/algolia/ratings';
import { AlgoliaAttributes } from '@utils/constants';
import '../../../styles/toggle-refinement.scss';

const FallbackComponent = ({ attribute }: { attribute: string }) => {
  const renderComponent = () => {
    if (attribute === AlgoliaAttributes.Price) {
      return (
        <div className="mb-2">
          <PriceSlider attribute={attribute} />
        </div>
      );
    }

    if (attribute === AlgoliaAttributes.Rating) {
      return <Ratings attribute={attribute} />;
    }

    if (attribute === AlgoliaAttributes.FreeShipping) {
      return (
        <ToggleRefinement
          attribute={attribute}
          label="Display only items with free shipping"
          on={true}
        />
      );
    }

    return <AlgoliaRefinementList attribute={attribute} />;
  };

  return <Panel header={attribute}>{renderComponent()}</Panel>;
};

export default FallbackComponent;
