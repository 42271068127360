import { useTranslation } from 'next-i18next';
import { Drawer } from '@components/common/drawer/drawer';
import FilterIcon from '@components/icons/filter-icon';
import AlgoliaSortBy from '@components/algolia/algolia-sort-by';
import AlgoliaFilterSidebar from '@components/algolia/algolia-filter-sidebar';
import useUiStore from 'src/stores/uiStore';
import { useRouter } from 'next/router';
import { getDirection } from '@utils/get-direction';
import { getAppConfig } from '@utils/get-app-config';

interface Props {
  onNavClick: any;
  viewAs: boolean;
}

const AlgoliaTopBar: React.FC<Props> = ({ onNavClick, viewAs }) => {
  const { openFilter, displayFilter, closeFilter } = useUiStore();
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const config = getAppConfig();
  const dir = getDirection(locale);
  const contentWrapperCSS = dir === 'ltr' ? { left: 0 } : { right: 0 };

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start mb-6 filters-panel">
      <button
        className="lg:hidden text-skin-base text-sm px-4 py-2 font-semibold border border-skin-base rounded flex items-center mb-5"
        onClick={openFilter}
      >
        <FilterIcon />
        <span className="ps-2.5">{t('text-filters')}</span>
      </button>
      <div className="flex w-full items-center lg:justify-between">
        <div className="list-view">
          <div className="btn btn-gridview text-skin-base text-opacity-70">
            View as:
          </div>
          <button
            type="button"
            id="grid-5"
            className={`btn btn-view grid ${(viewAs && 'active') || ''}`}
            onClick={() => onNavClick(!viewAs)}
            title="Grid"
          >
            <div>
              <div className="icon-bar"></div>
              <div className="icon-bar"></div>
              <div className="icon-bar"></div>
            </div>
          </button>
          <button
            type="button"
            id="list-view"
            className={`btn btn-view list ${(!viewAs && 'active') || ''}`}
            onClick={() => onNavClick(!viewAs)}
            title="List"
          >
            <div>
              <div className="icon-bar"></div>
              <div className="icon-bar"></div>
              <div className="icon-bar"></div>
            </div>
          </button>
        </div>

        <AlgoliaSortBy
          items={[
            {
              label: 'Featured',
              value: config.ALGOLIA_INDEXNAME,
            },
            {
              label: 'Price ascending',
              value: config.ALGOLIA_PRICE_ASC_INDEXNAME,
            },
            {
              label: 'Price descending',
              value: config.ALGOLIA_PRICE_DESC_INDEXNAME,
            },
          ]}
        />
      </div>
      <Drawer
        placement={dir === 'rtl' ? 'right' : 'left'}
        open={displayFilter}
        onClose={closeFilter}
        handler={false}
        showMask={true}
        level={null}
        contentWrapperStyle={contentWrapperCSS}
      >
        <AlgoliaFilterSidebar />
      </Drawer>
    </div>
  );
};

export default AlgoliaTopBar;
