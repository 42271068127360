import Logo from '@components/ui/logo';
import useUiStore from 'src/stores/uiStore';

const SiteLogo = () => {
  const { headerData } = useUiStore();

  return (
    <Logo
      image={headerData?.logo}
      isLoading={false}
      className="logo ps-3 md:ps-0 lg:mx-0"
    />
  );
};

export default SiteLogo;
