import Alert from '@components/ui/alert';
import CategoryListCardLoader from '@components/ui/loaders/category-list-card-loader';
import cn from 'classnames';
import CategoryMenu from '@components/ui/category-menu';
import { ISHCategoryType } from 'src/domain/commerce/category/category-menu';

interface CategoryDropdownProps {
  data?: ISHCategoryType[];
  isLoading: boolean;
  error: Error | null;
  className?: string;
  categoriesLimit?: number;
}

const CategoryDropdownMenu: React.FC<CategoryDropdownProps> = ({
  data,
  isLoading,
  error,
  className,
  categoriesLimit = 12,
}) => {
  const hasData = Boolean(data?.length);

  const renderContent = () => {
    if (error) {
      return (
        <div className="2xl:pe-10">
          <Alert message={error.message} />
        </div>
      );
    }

    if (isLoading && !hasData) {
      return (
        <div
          className={
            'w-full bg-skin-fill border-t-0 border-2 border-skin-primary rounded-b-md category-dropdown-menu'
          }
        >
          {Array.from({ length: 6 }).map((_, idx) => (
            <CategoryListCardLoader
              key={`category-list-${idx}`}
              uniqueKey="category-list-card-loader"
            />
          ))}
        </div>
      );
    }

    return <CategoryMenu items={data} categoriesLimit={categoriesLimit} />;
  };

  return (
    <div className={cn('absolute z-30 w-72 lg:w-full ', className)}>
      <div className="max-h-full">{renderContent()}</div>
    </div>
  );
};

export default CategoryDropdownMenu;
