import { QueryOptionsType } from '@framework/types';
import { useQuery } from '@tanstack/react-query';
import { Footer } from 'src/domain/cms/footer/footer';
import { ContentfulGateway } from 'src/gateway/cms/contentful-gateway';
import { getFooterData } from 'src/use-cases/get-footer-content';

const footerController = () => {
  const fetchFooterContent = async ({ queryKey }: any) => {
    const [_key, _params] = queryKey;
    return await getFooterData(ContentfulGateway());
  };

  const useFooterContent = (options: QueryOptionsType) => {
    return useQuery<Footer, Error>(
      ['footerContent', options],
      fetchFooterContent,
      { enabled: options.enabled },
    );
  };

  return {
    footer: {
      fetch: fetchFooterContent,
      use: useFooterContent,
    },
  };
};

export default footerController();
