import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import cn from 'classnames';
import SearchIcon from '@components/icons/search-icon';
import useFreezeBodyScroll from '@utils/use-freeze-body-scroll';
import useUiStore from 'src/stores/uiStore';
const AlgoliaSearch = dynamic(
  () => import('@components/algolia/algolia-search'),
);

type Props = {
  className?: string;
  searchId?: string;
  variant?: 'border' | 'fill';
};

const Search = ({
  className = 'md:w-[730px] 2xl:w-[800px]',
  searchId = 'search',
  variant = 'border',
}: Props) => {
  const { t } = useTranslation('forms');
  const router = useRouter();
  const { displayMobileSearch, toggleMobileSearch, closeMobileSearch } =
    useUiStore();
  useFreezeBodyScroll(displayMobileSearch);

  // This will close the algolia search modal on product link click
  useEffect(() => {
    const handleRouteChange = () => {
      if (displayMobileSearch) {
        closeMobileSearch();
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
  }, [closeMobileSearch, displayMobileSearch, router.events]);

  return (
    <div
      className={cn(
        'w-full transition-all duration-200 ease-in-out',
        className,
      )}
    >
      <div className="w-full flex flex-col justify-center flex-shrink-0 relative z-30">
        <div className="flex flex-col mx-auto w-full">
          <form className="flex w-full relative " noValidate role="search">
            <label
              htmlFor={searchId}
              className="flex flex-1 items-center py-0.5"
            >
              <input
                id={searchId}
                className={cn(
                  'text-heading outline-none w-full h-[45px] ps-5 md:ps-6 pe-14 md:pe-16 bg-skin-full text-skin-base text-sm rounded-full transition-all duration-200 focus:border-skin-primary focus:ring-1 focus:ring-skin-form',
                  {
                    'border border-skin-base': variant === 'border',
                    'bg-skin-one': variant === 'fill',
                  },
                )}
                placeholder={t('placeholder-search')}
                aria-label={searchId}
                autoComplete="off"
                onFocus={toggleMobileSearch}
              />
            </label>
            <span className="w-14 md:w-16 h-full absolute top-0 end-0 flex flex-shrink-0 justify-center items-center focus:outline-none">
              <SearchIcon className="w-5 h-5 text-skin-base text-opacity-40" />
            </span>
          </form>
        </div>
      </div>
      {displayMobileSearch && (
        <AlgoliaSearch toggleAlgoliaSearch={toggleMobileSearch} />
      )}
    </div>
  );
};

export default Search;
