import Image from 'next/image';
import SupperCategoryList from '@components/suppercategory/suppercategory-list';
import ProductCarouselFeed from './product-carousel-feed';
import { CategoryType } from 'src/domain/cms/home/shop-by-category';

interface Props {
  data?: CategoryType;
  isLoading: boolean;
  error?: Error | null;
  disableAlgoliaFetch?: boolean;
}
const PopularCategorylist: React.FC<Props> = ({
  data,
  isLoading,
  error,
  disableAlgoliaFetch = false,
}) => {
  const { commerceProductCarousel, subCategories, name } = data ?? {};
  const backgroundThumbnail = data?.image?.desktop?.url;

  const breakpoints = {
    '1280': {
      slidesPerView: 5,
    },
    '1024': {
      slidesPerView: 4,
    },
    '640': {
      slidesPerView: 3,
    },
    '360': {
      slidesPerView: 2,
    },
    '0': {
      slidesPerView: 1,
    },
  };

  return (
    <div>
      {isLoading ? (
        <p>loading...</p>
      ) : (
        <div className="mb-8 lg:mb-12">
          <div className="xl:flex border border-black/10">
            <div className={`xl:w-[420px] p-7 bg-no-repeat bg-right relative`}>
              {backgroundThumbnail && (
                <Image
                  src={backgroundThumbnail}
                  fill
                  alt={name ?? ''}
                  className="object-scale-down object-right -z-10"
                />
              )}
              <SupperCategoryList
                className={`supper-category--list`}
                data={{ name, subCategories }}
              />
            </div>

            <div className="trendy-main-content w-full p-2.5">
              <ProductCarouselFeed
                data={commerceProductCarousel}
                isLoading={isLoading}
                error={error}
                algoliaFetchDisable={disableAlgoliaFetch}
                showHeading={false}
                carouselBreakpoint={breakpoints}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopularCategorylist;
