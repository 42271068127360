import { useRefinementList, UseRefinementListProps } from 'react-instantsearch';
import { CheckBox } from '@components/ui/form/checkbox';

const AlgoliaRefinementList = (props: UseRefinementListProps) => {
  const { items, refine } = useRefinementList(props);

  return (
    <>
      {items.map((item) => (
        <CheckBox
          key={`${item.label}-key-${item.value}`}
          label={item.label}
          name={item.label.toLowerCase()}
          value={item.label}
          checked={item.isRefined}
          onChange={() => refine(item.value)}
        />
      ))}
    </>
  );
};

export default AlgoliaRefinementList;
