export const conversion_data = (
  indexName: string,
  objectId: string,
  userToken: string,
) => {
  return {
    events: [
      {
        eventType: 'conversion',
        eventName: 'Add To Cart',
        index: indexName,
        objectIDs: [objectId],
        userToken: userToken,
      },
    ],
  };
};

export const wishlist_data = (
  indexName: string,
  objectId: string,
  userToken: string,
) => {
  return {
    events: [
      {
        eventType: 'conversion',
        eventName: 'Add To Wishlist',
        index: indexName,
        objectIDs: [objectId],
        userToken: userToken,
      },
    ],
  };
};

export const click_data = (
  indexName: string,
  objectId: string,
  userToken: string,
) => {
  return {
    events: [
      {
        eventType: 'click',
        eventName: 'Product Clicked',
        index: indexName,
        objectIDs: [objectId],
        userToken: userToken,
      },
    ],
  };
};
