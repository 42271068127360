import cn from 'classnames';
import Image from '@components/ui/image';
import Link from '@components/ui/link';
import { getPrice } from '@framework/product/use-price';
import { Product } from '@framework/types';
import { useModalAction } from '@components/common/modal/modal.context';
import useWindowSize from '@utils/use-window-size';
import SearchIcon from '@components/icons/search-icon';
import { AddToCart } from '@components/product/add-to-cart';
import { useTranslation } from 'next-i18next';
import { productPlaceholder } from '@assets/placeholders';
import { ROUTES } from '@utils/routes';

import { sendProductClick } from '../../../use-cases/send-product-click';
import { AlgoliaRecommendGateway } from '../../../gateway/algolia/algolia-recommend-gateway';
import Cookies from 'js-cookie';

interface ProductProps {
  product: Product;
  className?: string;
}
function RenderPDPLinkOrAddToCart({ product }: { readonly product: Product }) {
  const { t } = useTranslation('common');
  const { variations } = product ?? {};
  if (variations?.length) {
    return (
      <Link
        className={
          'min-w-[150px] px-4 py-2 bg-skin-primary text-skin-inverted text-[13px] items-center justify-center focus:outline-none focus-visible:outline-none rounded-full'
        }
        href={`${ROUTES.PRODUCTS}/${product.slug}`}
      >
        {t('text-view')}
      </Link>
    );
  }
  return <AddToCart product={product} />;
}

const ProductCard: React.FC<ProductProps> = ({ product, className }) => {
  const { name, image, unit, slug, sku, variations } = product ?? {};
  const userTokenCookie = Cookies.get('userToken');
  const userToken = userTokenCookie ?? 'test_token';
  const { openModal } = useModalAction();
  const { t } = useTranslation('common');
  const { width } = useWindowSize();
  const iconSize = width! > 1024 ? '20' : '17';
  const basePrice = null;

  function handlePopupView() {
    openModal('PRODUCT_VIEW', product);
  }

  const variation_text = variations?.length
    ? variations.length + ' ' + t('text-variations')
    : '';
  const formatted_price = getPrice(product);

  return (
    <article
      className={cn(
        'flex flex-col product-card relative card-image--jump px-2 sm:px-3 overflow-hidden  h-full',
        className,
      )}
      title={name}
    >
      <div className="bg-white relative flex-shrink-0  mt-4">
        <div className="card-img-container flex overflow-hidden max-w-[230px] mx-auto relative">
          <Image
            src={image?.thumbnail ?? productPlaceholder}
            alt={name || 'Product Image'}
            width={230}
            height={200}
            quality={100}
            className="object-cover bg-skin-thumbnail"
          />
        </div>
        <div className="w-full h-full absolute top-0 pt-2.5 md:pt-3.5 z-10 -mx-0.5 sm:-mx-1">
          <button
            className="buttons--quickview px-4 py-2 bg-skin-inverted rounded-full hover:bg-skin-primary hover:text-skin-inverted"
            aria-label="Quick View Button"
            onClick={handlePopupView}
          >
            <SearchIcon width={iconSize} height={iconSize} opacity="1" />
          </button>
        </div>
      </div>

      <div className="bg-white flex flex-col mb-2 h-full overflow-hidden text-center relative">
        <div className="text-sm uppercase mt-auto text-11px text-purple-800 mb-1.5">
          {unit}
        </div>
        <Link
          onClick={() =>
            sendProductClick(AlgoliaRecommendGateway(), sku!, userToken)
          }
          href={`${ROUTES.PRODUCTS}/${slug}`}
          className="text-skin-base text-sm leading-5  line-clamp-2 mb-2 hover:text-skin-primary"
        >
          {name}
        </Link>
        <div className="space-s-2 mb-4 lg:mb-4">
          <span className="inline-block font-bold text-15px lg:text-base">
            {formatted_price}
          </span>
          {basePrice && (
            <del className="text-sm text-gray-600 text-opacity-70">
              {basePrice}
            </del>
          )}
          <div>{variation_text}</div>
        </div>

        <div className="inline-block product-cart-button">
          <RenderPDPLinkOrAddToCart product={product} />
        </div>
      </div>
    </article>
  );
};

export default ProductCard;
