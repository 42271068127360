import LatestblogCard from '@components/cards/latestblog-card';
import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from '@components/ui/carousel/slider';
import { ROUTES } from '@utils/routes';
import useWindowSize from '@utils/use-window-size';
import { useInfiniteHits } from 'react-instantsearch';
import SectionHeader from './section-header';

interface BlogProps {
  data?: any;
  isLoading?: boolean;
  className?: string;
}

const breakpoints = {
  '1536': {
    slidesPerView: 5,
  },
  '1280': {
    slidesPerView: 4,
  },
  '1024': {
    slidesPerView: 3,
  },
  '768': {
    slidesPerView: 2,
  },
  '540': {
    slidesPerView: 2,
  },
  '0': {
    slidesPerView: 1,
  },
};

const LatestblogCarousel: React.FC<BlogProps> = ({
  data,
  className = 'mb-2 pb-1 lg:pb-0 3xl:pb-2.5',
}) => {
  const { width } = useWindowSize();
  const { hits } = useInfiniteHits();

  let limit = data?.fields?.maxItem;
  if (width! < 767) limit = 3;
  const dataBlog = hits.slice(0, limit);
  return (
    <div className={className}>
      <SectionHeader
        sectionHeading={data?.fields?.title ?? ''}
        className="mb-6 block-title"
      />
      <Carousel
        breakpoints={breakpoints}
        autoplay={false}
        navigation={true}
        prevActivateId="latestblog-carousel-button-prev"
        nextActivateId="latestblog-carousel-button-next"
      >
        {dataBlog?.map((item: any) => (
          <SwiperSlide
            key={`collection-key-${item.entityId}`}
            className="px-1.5 md:px-2 xl:px-3 py-5"
          >
            <LatestblogCard
              collection={item}
              href={`${ROUTES.BLOG}/${item.slug}`}
            />
          </SwiperSlide>
        ))}
      </Carousel>
    </div>
  );
};

export default LatestblogCarousel;
