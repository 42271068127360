import { CategoryType } from 'src/domain/cms/home/shop-by-category';
import { mapImageType } from './common-mapper';

export function mapCategory(category: any): CategoryType {
  const image = category?.fields?.image;

  return {
    key: category.fields.id,
    name: category.fields.name,
    image: image?.fields ? mapImageType(image) : undefined,
    slug: category.fields.categorySlug,
    subCategories: mapCategories(category.fields.subCategories),
  };
}

export function mapCategories(subCategories: any): CategoryType[] {
  if (!subCategories || subCategories.length <= 0) {
    return [];
  }

  const mappedSubCategories = new Array();
  subCategories.forEach((subCategory: any) => {
    const mappedCategory = mapCategory(subCategory);
    mappedSubCategories.push(mappedCategory);
  });

  return mappedSubCategories;
}
