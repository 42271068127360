import {
  HalfBanner,
  HalfBannerResponse,
} from 'src/domain/cms/home/half-banner';
import { GridType } from '@components/common/banner-grid-two';

export function mapHomeHalfBanner(apiData: any): HalfBannerResponse {
  const result: HalfBanner[] = [];
  const banners = apiData?.fields ?? [];

  const types = ['left', 'right'];
  const sameWidth = banners?.sameWidth ?? true;
  const gridType = sameWidth ? GridType.Half : GridType.OneThird;
  types?.forEach((type: string) => {
    const title = banners?.[`${type}Title`] || '';
    const slug = banners?.[`${type}Slug`] || '';
    const desktopImage = banners?.[`${type}DesktopImage`]?.fields?.file;
    const mobileImage = banners?.[`${type}MobileImage`]?.fields?.file;

    const halfBanner: HalfBanner = {
      title,
      slug,
      gridType,
      image: {
        desktop: {
          url: desktopImage?.url ? `https:${desktopImage.url}` : '',
          width: desktopImage?.details?.image?.width || 0,
          height: desktopImage?.details?.image?.height || 0,
        },
        mobile: {
          url: mobileImage?.url ? `https:${mobileImage.url}` : '',
          width: mobileImage?.details?.image?.width || 0,
          height: mobileImage?.details?.image?.height || 0,
        },
      },
    };
    result.push(halfBanner);
  });

  return { gridType, banners: result };
}
