import cn from 'classnames';
import Link from '@components/ui/link';
import { BsChevronDown } from 'react-icons/bs';
import ListMenu from '@components/ui/list-menu';
import SubMega from '@components/ui/mega/sub-mega';
import { Navigation, NavigationItem } from 'src/domain/cms/header/navigation';

interface MenuProps {
  data: Navigation | undefined;
  isLoading: boolean;
  className?: string;
  bgPrimary?: boolean;
  activeMenu: string;
  setActiveMenu: React.Dispatch<React.SetStateAction<string>>;
}

const HeaderMenu: React.FC<MenuProps> = ({
  data,
  isLoading,
  className,
  bgPrimary,
  activeMenu,
  setActiveMenu,
}) => {
  return (
    <nav className={cn('headerMenu flex w-full ', className)}>
      {!isLoading &&
        data?.items.map((item: NavigationItem) => (
          <div
            className={cn('menuItem group  py-3 mx-4 xl:mx-5', {
              relative: item.menuType != 'mega',
              activeMenu: activeMenu === item.key,
            })}
            key={item.key}
            onMouseEnter={() => setActiveMenu(item.key)}
            onMouseLeave={() => setActiveMenu('')}
          >
            <Link
              href={item?.link?.href ?? item?.link?.slug ?? '#'}
              className={`uppercase inline-flex items-center text-sm text-white py-2 font-medium relative  ${
                !bgPrimary && 'group-hover:text-skin-primary'
              }`}
            >
              {item.name}
              {item.subItems && item.subItems.length > 0 && (
                <span
                  className={`text-xs w-4 flex justify-end text-white opacity-80 ${
                    !bgPrimary && 'group-hover:text-skin-primary'
                  }`}
                >
                  <BsChevronDown className="transition duration-300 ease-in-out transform " />
                </span>
              )}
            </Link>

            {item?.subItems && item.subItems.length > 0 && (
              <>
                {item?.menuType != 'mega' ? (
                  <div className="subMenu shadow-dropDown bg-skin-fill z-30 absolute start-0 opacity-0 group-hover:opacity-100">
                    <ul className="text-body text-sm py-5">
                      {item.subItems.map((menu: any, index: number) => {
                        const dept: number = 1;
                        const menuName: string = `sidebar-menu-${dept}-${index}`;
                        return (
                          <ListMenu
                            dept={dept}
                            data={menu}
                            hasSubMenu={
                              (menu.subItems && menu.subItems.length > 0) ??
                              (menu.subCategories &&
                                menu.subCategories.length > 0)
                            }
                            menuName={menuName}
                            key={menuName}
                            menuIndex={index}
                          />
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <SubMega item={item} />
                )}
              </>
            )}
          </div>
        ))}
    </nav>
  );
};

export default HeaderMenu;
