import axios from 'axios';
import { mapCategoryMenu } from '../../utils/mappers/intershop-mapper';
import { intershopSettings } from '../../settings/intershop-settings';
import { CategoryService } from 'src/domain/commerce/category/category-service';
import { ISHCategoryType } from 'src/domain/commerce/category/category-menu';

const settings = intershopSettings[process.env.NODE_ENV];

const IntershopCategoryGateway = (): CategoryService => {
  const getCategoryMenu = async (): Promise<ISHCategoryType[]> => {
    const response = await axios.get(
      `${settings.baseUrl}${settings.endpoints.category}`,
      {
        params: {
          imageView: 'NO-IMAGE',
          view: 'tree',
          limit: 2,
          omitHasOnlineProducts: true,
        },
      },
    );

    return mapCategoryMenu(response);
  };

  return {
    getCategoryMenu,
  };
};

export { IntershopCategoryGateway };
