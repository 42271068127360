type LocaleType = {
  [key: string]: string;
};

export const LOCALES: LocaleType = {
  DEFAULT: 'en-US',
  EN: 'en-US',
  DE: 'de-DE',
  FR: 'fr-FR',
};
