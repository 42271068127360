import {
  Banner,
  ImageAttributes,
  ImageType,
  LinkType,
} from 'src/domain/cms/shared/common';

export function mapLink(link: any): LinkType | undefined {
  if (!link) {
    return;
  }

  return {
    name: link.fields.name,
    slug: link.fields.slug,
    href: link.fields.href,
  };
}

export function mapImageType(image: any): ImageType {
  const desktopImage = image.fields.desktopImage;
  const desktopHeight = image.fields.desktopHeight;
  const desktopWidth = image.fields.desktopWidth;
  const mobileImage = image.fields.mobileImage;
  const mobileHeight = image.fields.mobileHeight;
  const mobileWidth = image.fields.mobileWidth;

  return {
    desktop: mapImageAttributes(desktopImage, desktopHeight, desktopWidth),
    mobile: mobileImage
      ? mapImageAttributes(mobileImage, mobileHeight, mobileWidth)
      : undefined,
  };
}

function mapImageAttributes(
  imageAttributes: any,
  optionalHeight: any,
  optionalWidth: any,
): ImageAttributes {
  const { file } = imageAttributes.fields;
  const mappedUrl = file.url ? `https:${file.url}` : '';
  const { width, height } = file.details.image;

  return {
    url: mappedUrl,
    width: optionalWidth || width,
    height: optionalHeight || height,
  };
}

export function mapBanner(banner: any): Banner {
  const { id, title, subtitle, image, buttonText, buttonRef } =
    banner?.fields ?? {};
  return {
    id,
    title,
    subTitle: subtitle ?? '',
    image: mapImageType(image),
    buttonText: buttonText,
    buttonRef: buttonRef,
  };
}
