import { Navigation, NavigationItem } from 'src/domain/cms/header/navigation';
import { CategoryType } from 'src/domain/cms/home/shop-by-category';
import { mapImageType, mapLink } from '../common/common-mapper';
import { mapCategory } from '../common/category-mapper';

export function mapNavigation(apiData: any): Navigation {
  const menuItems = apiData.fields.navigation.fields.menuItems ?? [];

  return {
    title: apiData.fields.navigation.fields.title,
    items: mapNavigationItems(menuItems),
  };
}

function mapNavigationItems(menuItems: any): NavigationItem[] {
  const mappedNavigationItems: NavigationItem[] = new Array();

  menuItems.forEach((menuItem: any) => {
    const mappedNavigationItem = mapNavigationItem(menuItem);
    mappedNavigationItems.push(mappedNavigationItem);
  });

  return mappedNavigationItems;
}

function mapNavigationItem(item: any): NavigationItem {
  const link = item?.fields?.link;
  const image = item?.fields?.image;

  return {
    key: item.fields.key,
    name: item.fields.name,
    link: link?.fields ? mapLink(link) : undefined,
    image: image?.fields ? mapImageType(image) : undefined,
    menuType: item.fields.menuType,
    bottomText: item.fields.bottomText,
    subItems: mapSubNavigationItems(item.fields.subItems),
  };
}

function mapSubNavigationItems(
  subItems: any,
): NavigationItem[] | CategoryType[] {
  if (!subItems || subItems.length <= 0) {
    return [];
  }

  const mappedSubItems = new Array();
  subItems.forEach((subItem: any) => {
    if (subItem.fields?.menuType) {
      const mappedNavigationItem = mapNavigationItem(subItem);
      mappedSubItems.push(mappedNavigationItem);
    } else {
      const mappedCategory = mapCategory(subItem);
      mappedSubItems.push(mappedCategory);
    }
  });

  return mappedSubItems;
}
