import { BrandSearch } from 'src/domain/cms/home/brand-search';

export function mapHomeBrandSearch(apiData: any): BrandSearch[] {
  const result: BrandSearch[] = new Array();
  const brandSearch = apiData?.fields.items ?? [];

  brandSearch.forEach((element: any) => {
    const desktopImage = element.fields.desktopImage.fields.file.url;
    const mobileImage = element.fields.mobileImage.fields.file.url;

    const brandSearchItem: BrandSearch = {
      id: element.fields.id2,
      title: element.fields.title,
      image: {
        desktop: {
          url: `https:${desktopImage}`,
          width: 190,
          height: 59,
        },
        mobile: {
          url: `https:${mobileImage}`,
          width: 190,
          height: 59,
        },
      },
      slug: element.fields?.slug2 ?? '',
    };
    result.push(brandSearchItem);
  });

  return result;
}
