import Widgets from '@components/layout/footer/widget/widget';
import Copyright from '@components/layout/footer/copyright';
import footerController from 'src/controller/footer-controller';

const Footer = ({ footerData }: any) => {
  const { data } = footerController.footer.use({
    enabled: !footerData?.fatFooter,
  });
  const footerCmsData = footerData?.fatFooter ? footerData : data;

  return (
    <footer className="footer-one bg-skin-footer text-gray-400">
      <Widgets
        widgets={footerCmsData?.fatFooter?.widgets}
        helpLineData={footerCmsData?.fatFooter?.helpLineData ?? {}}
        newsLetterData={footerCmsData?.fatFooter?.newsLetterData ?? {}}
      />
      <Copyright
        copyright={footerCmsData?.copyright}
        payment={footerCmsData?.paymentOptions}
      />
    </footer>
  );
};

export default Footer;
