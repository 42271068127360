import algoliasearch from 'algoliasearch/lite';
import { Configure, InstantSearch } from 'react-instantsearch';
import Cookies from 'js-cookie';
import SectionHeader from '@components/common/section-header';
import AlogliaProducts from '@components/algolia/algolia-products';
import { getAppConfig } from '@utils/get-app-config';

interface CategoryProductsGridProps {
  title: string;
  categoryId: string;
  hitsPerPage?: number;
}

const config = getAppConfig();
const userTokenCookie = Cookies.get('userToken');
const userToken = userTokenCookie ?? 'test_token';

const searchClient = algoliasearch(config.ALGOLIA_APPID, config.ALGOLIA_APIKEY);

const CategoryProductsGrid = ({
  categoryId,
  title,
  hitsPerPage = 20,
}: CategoryProductsGridProps) => {
  return (
    <>
      {title && (
        <div className=" mb-5 md:mb-6">
          <SectionHeader sectionHeading={title} className="mb-0 block-title" />
        </div>
      )}
      <InstantSearch
        searchClient={searchClient}
        indexName={config.ALGOLIA_INDEXNAME}
        insights={true}
      >
        <Configure
          filters={`categories.slug:${categoryId}`}
          hitsPerPage={hitsPerPage}
          enablePersonalization={true}
          userToken={userToken}
        />
        <div className="grid-container w-full">
          <AlogliaProducts showHeader={false} showSidebar={false} />
        </div>
      </InstantSearch>
    </>
  );
};

export default CategoryProductsGrid;
