import Link from '@components/ui/link';

const DraftModeNotification = () => {
  return (
    <div className="text-center p-2">
      You are currently viewing the app in preview mode.{' '}
      <Link
        href="/api/disable-draft"
        className="bg-red-800 text-white px-2 py-1 rounded"
      >
        Exit Preview
      </Link>
    </div>
  );
};

export default DraftModeNotification;
