import { Footer } from 'src/domain/cms/footer/footer';
import { mapPaymentOptions } from './payment-option-mapper';
import { getFooterMenuLinks } from './get-footer-menu-links';
import { getHelpTextData } from './get-help-text-data';
import { getNewsletterTextData } from './get-newsletter-text-data';

export function mapFooter(apiData: any): Footer {
  const footerCopyRight = apiData?.fields?.footerCopyRight[0];
  const fatFooter = apiData?.fields?.fatFooter;
  const helpLineResponse = fatFooter[0];
  const newsLetterResponse = fatFooter[2];

  const footerMenus = fatFooter[1]?.fields?.footerMenuContainer ?? [];
  const widgets = getFooterMenuLinks(footerMenus);
  const helpLineData = getHelpTextData(helpLineResponse);
  const newsLetterData = getNewsletterTextData(newsLetterResponse);
  return {
    paymentOptions: mapPaymentOptions(apiData),
    fatFooter: {
      widgets,
      helpLineData,
      newsLetterData,
    },
    copyright: {
      copyrightAuthorName: footerCopyRight.fields.copyrightAuthorName,
      copyright: footerCopyRight.fields.copyright,
      allRightsReserved: footerCopyRight.fields.allRightsReserved,
      url: '',
    },
  };
}
