import { Product } from "@framework/types";
import { intershopSettings } from "@settings/intershop-settings";
import { CommerceProduct } from "src/domain/commerce/product/types";

const settings = intershopSettings[process.env.NODE_ENV];

export default function intershopProductMapper(elements: CommerceProduct[]) : Product[] { 
    const baseUrl = settings.domain;
    return elements.map(({ name, productName, sku, salePrice, defaultCategory, images }: CommerceProduct) => ({
        id: sku || '',
        slug: defaultCategory?.uri || '',
        name : name,
        price: salePrice?.value || 0,
        quantity: 100,
        sold: 7,
        unit: defaultCategory?.name || '',
        image: {
          id: 1,
          thumbnail: baseUrl+images[2].effectiveUrl || baseUrl+images[0].effectiveUrl,
          original: baseUrl+images[2].effectiveUrl || baseUrl+images[0].effectiveUrl,
        },
        sku: sku || '',
        description: productName || '',
    }));
}
  