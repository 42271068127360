import Link from '@components/ui/link';
import Image from '@components/ui/image';
import cn from 'classnames';
import { categoryPlaceholder } from '@assets/placeholders';
import { CategoryType } from 'src/domain/cms/home/shop-by-category';

interface Props {
  category: CategoryType;
  href: string;
  className?: string;
}

const CategoryListCard: React.FC<Props> = ({ category, className, href }) => {
  const { name, image, subCategories } = category;
  const SUBCATEGORIES_LIMITS = 5;
  return (
    <div className={`wb-categories__items`}>
      <Link
        href={href}
        className={cn(
          'group flex justify-between items-center px-0 transition pb-5',
          className,
        )}
      >
        <Image
          src={image?.desktop.url ?? categoryPlaceholder}
          alt={name || ''}
          width={255}
          height={160}
          className="bg-sink-thumbnail object-cover transition duration-200 ease-in-out transform group-hover:opacity-80"
        />
      </Link>

      <h3 className="text-[16px] text-skin-base capitalize  font-medium hover:text-skin-primary">
        <Link href={href}>{name}</Link>
      </h3>
      {Array.isArray(subCategories) ? (
        <ul key="content" className="py-3 text-[14px] leading-6">
          {subCategories
            .slice(0, SUBCATEGORIES_LIMITS)
            ?.map((currentItem: CategoryType, index: number) => {
              return (
                <li className="pb-1 hover:text-skin-primary" key={`${index}`}>
                  <Link href={currentItem?.slug}>{currentItem.name}</Link>
                </li>
              );
            })}
        </ul>
      ) : null}
    </div>
  );
};

export default CategoryListCard;
