import { ISHCategoryType } from 'src/domain/commerce/category/category-menu';
import { createSlug } from '@utils/tools';

export function mapCategoryMenu(apiData: any): ISHCategoryType[] {
  const mappedCategories: ISHCategoryType[] = new Array();
  const topCategories = apiData?.data?.elements || [];

  topCategories.forEach((topCategory: any) => {
    const mappedCategory = mapISHCategory(topCategory);
    mappedCategories.push(mappedCategory);
  });

  return mappedCategories;
}

function mapISHCategory(category: any): ISHCategoryType {
  const mappedSubCategories =
    category.subCategories && category.subCategories.length > 0
      ? mapISHSubCategories(category.subCategories)
      : undefined;

  return {
    key: category.id,
    name: category.name,
    slug: createSlug(category.name),
    subCategories: mappedSubCategories,
  };
}

function mapISHSubCategories(subCategories: any): ISHCategoryType[] {
  const mappedSubCategories: ISHCategoryType[] = new Array();

  subCategories.forEach((subCategory: any) => {
    const mappedSubCategory = mapISHCategory(subCategory);
    mappedSubCategories.push(mappedSubCategory);
  });

  return mappedSubCategories;
}
