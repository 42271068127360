import ProductsCarousel from '@components/product/products-carousel';
import { LIMITS } from '@framework/utils/limits';
import { ROUTES } from '@utils/routes';
import { CommerceProductCarousel } from 'src/domain/cms/product/types';

interface Props {
  data?: CommerceProductCarousel;
  isLoading?: boolean;
  error?: Error | null;
  algoliaFetchDisable: boolean;
  showHeading?: boolean;
  carouselBreakpoint?: object;
}

const ProductCarouselFeed: React.FC<Props> = ({
  data,
  showHeading = true,
  carouselBreakpoint,
}) => {
  let subheading = data?.title ?? '';

  return (
    <ProductsCarousel
      sectionHeading={subheading}
      categorySlug={ROUTES.PRODUCTS}
      products={data?.products ?? []}
      loading={false}
      error={''}
      limit={LIMITS.BEST_SELLER_PRODUCTS_LIMITS}
      uniqueKey="commerce-products"
      className="mb-8"
      showHeading={showHeading}
      carouselBreakpoint={carouselBreakpoint}
    />
  );
};

export default ProductCarouselFeed;
