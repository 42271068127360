import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';
import LatestblogCarousel from './latestblog-carousel';

const BlogCmsComponent: React.FC<any> = ({ data }) => {
  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APPID ?? '',
    process.env.NEXT_PUBLIC_ALGOLIA_APIKEY ?? '',
  );
  return (
    <div>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.NEXT_PUBLIC_ALGOLIA_BLOG_INDEX_NAME}
        insights={false}
      >
        <LatestblogCarousel data={data}></LatestblogCarousel>
      </InstantSearch>
    </div>
  );
};

export default BlogCmsComponent;
