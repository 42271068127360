export const formatCategoryName = (categoryName: string): string => {
  if (categoryName != undefined) {
    const categoryNameReplaced = categoryName
      .replaceAll('-', ' ')
      .replaceAll('_', ' ');
    return categoryNameReplaced.replace(/\w-_*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }
  return '';
};
