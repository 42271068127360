import { intershopSettings } from '@settings/intershop-settings';
import intershopProductMapper from '@utils/mappers/product/ish-product-mapper';
import trimEnd from 'lodash/trimEnd';
import { ProductService } from 'src/domain/commerce/product/product-service';

const settings = intershopSettings[process.env.NODE_ENV];
const apiBaseUrl = `${settings.baseUrl}${settings.endpoints.product}`;

const IntershopProductGateway = (): ProductService => {
  const fetchCommerceProducts = async (skuList: string[]) => {
    if (skuList.length < 1) {
      return [];
    }

    const responses = await Promise.all(
      skuList.map(async (sku: string) => {
        const response = await fetch(`${trimEnd(apiBaseUrl, '/')}/${sku}`);
        if (response.ok) {
          return response.json();
        } else {
          console.error(`Failed to fetch data for SKU: ${sku}`);
          return null; // Return null for failed requests
        }
      }),
    );

    const cleanResult = responses.filter((response) => response !== null); // Filter out null responses
    const mappedResponse = intershopProductMapper(cleanResult);
    return mappedResponse.filter((response) => response !== null);
  };

  return {
    fetchCommerceProducts,
  };
};

export { IntershopProductGateway };
