import { Item } from '@contexts/cart/cart.utils';
import { Product, Variation } from '@framework/types';
import isEmpty from 'lodash/isEmpty';

export function generateCartItem(item: Product, variation?: Variation): Item {
  const {
    id,
    lineItemId,
    name,
    slug,
    image,
    price,
    sale_price,
    quantity,
    unit,
  } = item;
  if (!!variation && !isEmpty(variation)) {
    const attributesName = variation.variation_attributes
      .map((attribute) => attribute.value)
      .join(', ');
    const variationName = `${name}, ${attributesName}`;

    return {
      id: variation.sku,
      lineItemId,
      productId: id,
      name: variationName,
      slug,
      unit,
      price: variation.price,
      image: image,
      variationId: variation.sku,
    };
  }
  return {
    id,
    lineItemId,
    name,
    slug,
    unit,
    image: image?.thumbnail,
    stock: quantity,
    price: sale_price ?? price,
  };
}
