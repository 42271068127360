import { mapProductCarousel } from './home/product-carousel-mapper';
import { ProductPage } from 'src/domain/cms/product/product-page';
import { HomePageEntries } from 'src/domain/cms/home/home-page';
import { Blog } from 'src/domain/cms/blog/blog';
import { ContentPageResponse } from 'src/domain/cms/content-page/content-page';
import { getComponentProps } from '@utils/getComponentProps';

export function mapHomePage(apiData: any): HomePageEntries {
  const result = apiData?.fields?.contentItems
    ?.map((item: any) => {
      return getComponentProps(item);
    })
    .filter((item: any) => item !== null);
  return result;
}

export function mapProductPage(apiData: any): ProductPage {
  return {
    commerceProductCarousel: mapProductCarousel(apiData),
  };
}

export function mapBlogData(blogData: any): Blog {
  let image = '';
  if (blogData.fields.image) {
    image = 'https:' + blogData.fields.image?.fields?.file?.url;
  }

  const blog: Blog = {
    title: blogData.fields.title,
    slug: blogData.fields.slug,
    authorName: blogData.fields.authorName,
    image: image,
    totalWatchCount: blogData.fields.watchCount,
    date: blogData.fields.date,
    category: blogData.fields.category || null,
    tags: blogData.fields.tags || null,
    content: blogData.fields.content,
    titleTwo: blogData.fields.titleTwo || null,
    contentTwo: blogData.fields.contentTwo || null,
    contentThree: blogData.fields.contentThree || null,
    quote: blogData.fields.quote || null,
    readingTime: blogData.fields.readingTime,
  };
  return blog;
}

export function mapContentPage(apiData: any): ContentPageResponse {
  const result = apiData?.items?.[0]?.fields?.content
    ?.map((item: any) => {
      return getComponentProps(item);
    })
    .filter((item: any) => item !== null);

  return {
    content: result,
    title: apiData?.items?.[0]?.fields?.title ?? '',
  };
}
