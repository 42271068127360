import { useState } from 'react';
import Link from '@components/ui/link';
import Scrollbar from '@components/ui/scrollbar';
import { IoIosArrowDown } from 'react-icons/io';
import Logo from '@components/ui/site-logo';
import useUiStore from 'src/stores/uiStore';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';

import {
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoFacebook,
  IoLogoYoutube,
  IoClose,
} from 'react-icons/io5';
import { KEYS } from '@utils/constants';
import { NavigationItem } from 'src/domain/cms/header/navigation';
import { CategoryType } from 'src/domain/cms/home/shop-by-category';
import { siteSettings } from '@settings/site-settings';

const social = [
  {
    id: 0,
    link: 'https://www.facebook.com/redqinc/',
    icon: <IoLogoFacebook size={24} />,
    className: 'facebook',
    title: 'text-facebook',
  },
  {
    id: 1,
    link: 'https://twitter.com/redqinc',
    icon: <IoLogoTwitter size={24} />,
    className: 'twitter',
    title: 'text-twitter',
  },
  {
    id: 2,
    link: 'https://www.youtube.com/channel/UCjld1tyVHRNy_pe3ROLiLhw',
    icon: <IoLogoYoutube size={24} />,
    className: 'youtube',
    title: 'text-youtube',
  },
  {
    id: 3,
    link: 'https://www.instagram.com/redqinc/',
    icon: <IoLogoInstagram size={24} />,
    className: 'instagram',
    title: 'text-instagram',
  },
];

const topMenuData = siteSettings.site_header.topmenu.map((item) => ({
  id: item.label.toLowerCase(),
  name: item.label,
  slug: item.path,
}));

const hasSubMenu = (items: NavigationItem[]) => {
  if (Array.isArray(items) && items.length) {
    return items;
  }

  return null;
};

const getLink = (item: NavigationItem | CategoryType) => {
  if ((item as NavigationItem)?.link) {
    const { link } = item as NavigationItem;
    return link?.href ?? link?.slug ?? '#';
  }

  return (item as CategoryType)?.slug ?? '#';
};

const ListMenu = ({
  dept,
  data,
  hasSubMenu,
  menuName,
  menuIndex,
  className = '',
}: any) => {
  const { closeSidebar } = useUiStore();
  const { t } = useTranslation('menu');
  const [activeMenus, setActiveMenus] = useState<any>([]);

  const handleArrowClick = (menuName: string) => {
    let newActiveMenus = [...activeMenus];
    if (newActiveMenus.includes(menuName)) {
      const index = newActiveMenus.indexOf(menuName);
      if (index > -1) {
        newActiveMenus.splice(index, 1);
      }
    } else {
      newActiveMenus.push(menuName);
    }
    setActiveMenus(newActiveMenus);
  };

  return (
    data?.name && (
      <li className={`transition-colors duration-200 ${className}`}>
        <div className="flex items-center justify-between relative">
          <Link
            href={getLink(data)}
            className="w-full menu-item relative py-4 ps-5 md:ps-7 pe-4 text-skin-base transition duration-300 ease-in-out"
          >
            <span
              className="block w-full"
              onClick={closeSidebar}
              onKeyDown={(e) => e.key === KEYS.ENTER && closeSidebar()}
            >
              {t(`${data.name}`)}
            </span>
          </Link>
          {hasSubMenu && (
            <div
              className="cursor-pointer w-full h-8 text-[17px] px-5 flex-shrink-0 flex items-center justify-end text-skin-base text-opacity-80 absolute end-0 top-1/2 transform -translate-y-1/2"
              onClick={() => handleArrowClick(menuName)}
              onKeyDown={(e) =>
                e.key === KEYS.ENTER && handleArrowClick(menuName)
              }
            >
              <IoIosArrowDown
                className={`transition duration-200 ease-in-out transform ${
                  activeMenus.includes(menuName) ? '-rotate-180' : 'rotate-0'
                }`}
              />
            </div>
          )}
        </div>
        {hasSubMenu && (
          <SubMenu
            dept={dept}
            data={hasSubMenu}
            toggle={activeMenus.includes(menuName)}
            menuIndex={menuIndex}
          />
        )}
      </li>
    )
  );
};

const SubMenu = ({ dept, data, toggle, menuIndex }: any) => {
  if (!toggle) {
    return null;
  }

  dept = dept + 1;

  return (
    <ul className={cn('mobile-sub-menu', dept > 2 && '-ms-4')}>
      {data?.map((menu: any, index: number) => {
        const menuName: string = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

        return (
          <ListMenu
            dept={dept}
            data={menu}
            hasSubMenu={hasSubMenu(menu.subItems || menu.subCategories)}
            menuName={menuName}
            key={menuName}
            menuIndex={index}
            className={cn(dept > 1 && 'ps-4', dept > 2 && 'ps-8')}
          />
        );
      })}
    </ul>
  );
};

export default function MobileMenu() {
  const { t } = useTranslation('menu');
  const { closeSidebar, headerData, categoryData } = useUiStore();
  const topMenuStartIndex = headerData?.navigation?.items?.length ?? 0;
  const allCategories = [
    {
      key: 'allCategories',
      name: 'AllCategories',
      slug: '/categories',
      subCategories: categoryData,
    },
  ];

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="bg-slate-600 w-full border-b border-skin-base flex justify-between items-center relative ps-5 md:ps-7 flex-shrink-0 py-0.5">
        <div
          onClick={closeSidebar}
          onKeyDown={(e) => e.key === KEYS.ENTER && closeSidebar()}
          className="inline-flex"
        >
          <Logo />
        </div>

        <button
          className="flex text-2xl items-center justify-center px-4 md:px-5 py-5 lg:py-8 focus:outline-none "
          onClick={closeSidebar}
          aria-label="close"
        >
          <IoClose className="text-skin-inverted mt-0.5" />
        </button>
      </div>

      <Scrollbar className="menu-scrollbar flex-grow mb-auto">
        <div className="flex flex-col py-1 px-0 text-skin-base">
          <ul className="mobile-menu">
            {allCategories?.map((menu: any, index: number) => {
              const dept: number = 1;
              const menuName: string = `sidebar-menu-${dept}-${index}`;

              return (
                <ListMenu
                  dept={dept}
                  data={menu}
                  hasSubMenu={hasSubMenu(menu.subItems || menu.subCategories)}
                  menuName={menuName}
                  key={menuName}
                  menuIndex={index}
                />
              );
            })}

            {headerData?.navigation?.items?.map((menu: any, index: number) => {
              const dept: number = 1;
              const menuName: string = `sidebar-menu-${dept}-${index}`;

              return (
                <ListMenu
                  dept={dept}
                  data={menu}
                  hasSubMenu={hasSubMenu(menu.subItems || menu.subCategories)}
                  menuName={menuName}
                  key={menuName}
                  menuIndex={index}
                />
              );
            })}

            {topMenuData?.map((menu: any, index: number) => {
              const dept: number = 1;
              const menuName: string = `sidebar-menu-${dept}-${
                topMenuStartIndex + index
              }`;

              return (
                <ListMenu
                  dept={dept}
                  data={menu}
                  hasSubMenu={false}
                  menuName={menuName}
                  key={menuName}
                  menuIndex={index}
                />
              );
            })}
          </ul>
        </div>
      </Scrollbar>

      <div className="flex items-center justify-center bg-skin-fill border-t border-skin-base px-7 flex-shrink-0 space-s-1 py-3">
        {social?.map((item, index) => (
          <Link
            href={item.link}
            className={`text-heading space-s-6 transition duration-300 ease-in text-skin-base text-opacity-60 hover:text-skin-primary ${item.className}`}
            key={index}
          >
            <span className="sr-only">{t(`${item.title}`)}</span>
            {item.icon}
          </Link>
        ))}
      </div>
    </div>
  );
}
