import Image from 'next/image';
import Link from '@components/ui/link';
import cn from 'classnames';
import { siteSettings } from '@settings/site-settings';
import { ImageType } from 'src/domain/cms/shared/common';

interface LogoProps extends React.AnchorHTMLAttributes<{}> {
  image?: ImageType;
  isLoading?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  className,
  type,
  href = siteSettings.logo.href,
  image = {
    desktop: {
      url: siteSettings.logo.url,
      height: siteSettings.logo.height,
      width: siteSettings.logo.width,
    },
  },
  isLoading = false,
  ...props
}) => {
  return (
    <Link
      href={href}
      className={cn('inline-flex focus:outline-none flex-shrink-0', className)}
      {...props}
    >
      {isLoading ? (
        <p>loading...</p>
      ) : (
        <Image
          src={image.desktop.url}
          alt="Logo"
          height={image.desktop.height}
          width={image.desktop.width}
          loading="eager"
        />
      )}
    </Link>
  );
};

export default Logo;
