import ListMenu from '@components/ui/mega/mega-menu';
import Container from '@components/ui/container';
import Image from '@components/ui/image';
import { productPlaceholder } from '@assets/placeholders';
import Link from '@components/ui/link';
import { NavigationItem } from 'src/domain/cms/header/navigation';

const SubMega = ({ item }: { item: NavigationItem }) => {
  let isBannerMode = false;
  if (item.image?.desktop.url) isBannerMode = true;
  const mega_categoryCol =
    item.subItems && item.subItems.length <= 6 ? item.subItems.length : 6;

  return (
    <div className="subMega shadow-dropDown bg-skin-fill  z-30 absolute start-0 opacity-0 group-hover:opacity-100">
      <Container className={'mx-auto max-w-screen-2xl'}>
        <div className={`flex flex-row gap-5 pt-8 py-5`}>
          <div
            className={`cateArea ${isBannerMode ? 'basis-3/4' : 'basis-full'} `}
          >
            <ul
              className={`text-body text-sm grid grid-cols-${mega_categoryCol} gap-4 `}
            >
              {item.subItems?.map((menu: any, index: number) => {
                const dept: number = 1;
                const menuName: string = `sidebar-menu-${dept}-${index}`;
                return (
                  <ListMenu
                    dept={dept}
                    data={menu}
                    hasSubMenu={
                      (menu.subItems && menu.subItems.length > 0) ??
                      (menu.subCategories && menu.subCategories.length > 0)
                    }
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                  />
                );
              })}
            </ul>
          </div>
          {isBannerMode && (
            <div className={`imageArea basis-1/4`}>
              <Link
                href={item?.link?.href ?? item?.link?.slug ?? '#'}
                className="text-skin-base "
              >
                <div className="max-w-[350px]">
                  <Image
                    src={item.image?.desktop.url ?? productPlaceholder}
                    alt={'Product Image'}
                    width={450}
                    height={300}
                    quality={100}
                    className="object-cover bg-skin-thumbnail"
                  />
                </div>
              </Link>
            </div>
          )}
        </div>
      </Container>
      {item.bottomText && item.bottomText.trim().length != 0 && (
        <div className="navPages-contentbottom bg-skin-carnation">
          <Container>
            <div className={`text-white text-sm text-center py-4`}>
              {item.bottomText}
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default SubMega;
