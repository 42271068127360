import { CMSService } from 'src/domain/cms/cms-service';
import { contentfulSettings } from '../../settings/contentful-settings';
import { HomePage } from '../../domain/cms/home/home-page';
import {
  mapProductPage,
  mapBlogData,
  mapContentPage,
} from '../../utils/mappers/contentful-mapper';
import * as contentful from 'contentful';
import { Footer } from 'src/domain/cms/footer/footer';
import { mapFooter } from '@utils/mappers/footer/footer-mapper';
import { Header } from 'src/domain/cms/header/header';
import {
  mapDefaultSearchContent,
  mapHeader,
} from '@utils/mappers/header/header-mapper';
import { ProductPage } from 'src/domain/cms/product/product-page';
import { LOCALES } from '@utils/constants/locales';
import { Blog } from 'src/domain/cms/blog/blog';
import { DefaultSearchContent } from 'src/domain/cms/header/default-search-content';
import homePageMapper from '@utils/mappers/home/home-page-mapper';

const client = contentful.createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? '',
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN ?? '',
});

const clientPreview = contentful.createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? '',
  accessToken: process.env.NEXT_PUBLIC_PREVIEW_CONTENTFUL_ACCESS_TOKEN ?? '',
  host: 'preview.contentful.com',
});

const settings = contentfulSettings[process.env.NODE_ENV];

const ContentfulGateway = (): CMSService => {
  const getHomePage = async (
    locale = LOCALES.DEFAULT,
    draftMode = false,
  ): Promise<HomePage> => {
    const dataClient = draftMode ? clientPreview : client;

    const result = await dataClient.getEntry(settings.homePageId, {
      include: 4,
      locale,
    });

    const data = await homePageMapper(result);
    return { data, dataRaw: result };
  };

  const getHeader = async (locale = LOCALES.DEFAULT): Promise<Header> => {
    const result = await client.getEntry(settings.headerId, {
      include: 5,
      locale,
    });
    return mapHeader(result);
  };

  const getDefaultSearchContent = async (
    locale = LOCALES.DEFAULT,
  ): Promise<DefaultSearchContent> => {
    const result = await client.getEntry(settings.defaultSearchContentId, {
      include: 3,
      locale,
    });
    return mapDefaultSearchContent(result);
  };

  const getFooter = async (): Promise<Footer> => {
    const result = await client.getEntry(settings.footerId, { include: 4 });
    return mapFooter(result);
  };
  const getProductPageData = async (): Promise<ProductPage> => {
    const result = await client.getEntry(settings.productPageId);
    return mapProductPage(result);
  };

  const getBlogEntryData = async (entryId: string): Promise<Blog> => {
    const result = await client.getEntry(entryId);
    return mapBlogData(result);
  };

  const getContentPageData = async (slug: string): Promise<any> => {
    const result = await client.getEntries({
      content_type: 'category-2',
      'fields.slug': slug,
      include: 3,
    });
    return { data: mapContentPage(result), dataRaw: result };
  };

  return {
    getHomePage,
    getHeader,
    getDefaultSearchContent,
    getFooter,
    getProductPageData,
    getBlogEntryData,
    getContentPageData,
  };
};

export { ContentfulGateway };
