import Alert from '@components/ui/alert';
import Counter from '@components/ui/counter';
import { useCart } from '@contexts/cart/cart.context';
import { generateCartItem } from '@utils/generate-cart-item';
import { useTranslation } from 'next-i18next';
import { useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import cartController, {
  UpdateLineItemInput,
} from 'src/controller/cart-controller';
import { Product, Variation } from '@framework/types';
interface Props {
  product: Product;
  variation?: Variation;
  disabled?: boolean;
  isBorderRounded?: boolean;
}

export const AddToCart = ({
  product,
  variation,
  disabled,
  isBorderRounded,
}: Props) => {
  const { t } = useTranslation('common');
  const { addItemToCart, removeItemFromCart, getItemFromCart, isInCart } =
    useCart();
  const { mutate: ishAddToCart, error: ishAddToCartError } =
    cartController.addToCart();
  const { mutate: ishUpdateCart, error: ishUpdateCartError } =
    cartController.updateCartLineItem();

  const item = generateCartItem(product, variation);
  const cartItem = getItemFromCart(item.id);

  // Reference and Callback are needed to keep track of the debounce state between UI re-renders
  const updateCartRef = useRef(ishUpdateCart);
  updateCartRef.current = (input: UpdateLineItemInput) => ishUpdateCart(input);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCart = useCallback(
    debounce((input) => updateCartRef.current(input), 500),
    [],
  );

  const handleButtonAddClick = (
    e: React.MouseEvent<HTMLButtonElement | MouseEvent>,
  ) => {
    e.stopPropagation();
    addItemToCart(item, 1);
    ishAddToCart({ sku: `${item.id}`, quantity: 1 });
  };
  const handleCounterAddClick = (
    e: React.MouseEvent<HTMLButtonElement | MouseEvent>,
  ) => {
    e.stopPropagation();
    addItemToCart(item, 1);
    debouncedUpdateCart({
      lineItemId: cartItem!.lineItemId,
      quantity: cartItem!.quantity! + 1,
    });
  };
  const handleCounterRemoveClick = (
    e: React.MouseEvent<HTMLButtonElement | MouseEvent>,
  ) => {
    e.stopPropagation();
    removeItemFromCart(item.id);
    debouncedUpdateCart({
      lineItemId: cartItem!.lineItemId,
      quantity: cartItem!.quantity! - 1,
    });
  };
  return !isInCart(item?.id) ? (
    <div>
      {!!ishAddToCartError && (
        <Alert message={'Something went wrong when adding item to cart.'} />
      )}
      <button
        className={`min-w-[150px] px-4 py-2 bg-skin-primary text-skin-inverted text-[13px] items-center justify-center focus:outline-none focus-visible:outline-none ${
          isBorderRounded ? 'rounded' : 'rounded-full'
        }`}
        aria-label="Count Button"
        onClick={handleButtonAddClick}
        disabled={disabled}
      >
        {t('text-add-to-cart')}
      </button>
    </div>
  ) : (
    <div>
      {!!ishUpdateCartError && (
        <Alert message={'Something went wrong when adding item to cart.'} />
      )}
      <Counter
        value={cartItem!.quantity!}
        onDecrement={handleCounterRemoveClick}
        onIncrement={handleCounterAddClick}
      />
    </div>
  );
};
