import { ShopByCategories } from 'src/domain/cms/home/shop-by-category';
import { mapCategories } from '../common/category-mapper';

export function mapShopByCategoriesWrapper(apiData: any): ShopByCategories {
  const { categories, title } = apiData.fields;

  return {
    title,
    categories: mapCategories(categories),
  };
}
