import { FooterNewsLetter } from 'src/domain/cms/footer/footer';

export const getNewsletterTextData = (
  newsLetterData: any,
): FooterNewsLetter => {
  const newsLetterTitle = newsLetterData?.fields?.title;
  const newsLetterText = newsLetterData?.fields?.textBlock;
  return {
    newsLetterText,
    newsLetterTitle,
  };
};
