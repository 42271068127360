import Link from '@components/ui/link';
import Image from '@components/ui/image';
import { NavigationItem } from 'src/domain/cms/header/navigation';
import { CategoryType } from 'src/domain/cms/home/shop-by-category';

const ListMenu = ({ dept, data, hasSubMenu, menuIndex }: any) => {
  const menuNavigationItem = data as NavigationItem;
  if (menuNavigationItem.menuType) {
    return (
      <li className="relative">
        {menuNavigationItem.image?.desktop.url && (
          <Link
            href={
              menuNavigationItem.link?.href ??
              menuNavigationItem.link?.slug ??
              '#'
            }
          >
            <Image
              src={
                menuNavigationItem.image?.desktop.url ??
                '/assets/placeholder/collection.svg'
              }
              alt={menuNavigationItem.name}
              width={255}
              height={160}
              className="bg-sink-thumbnail object-cover transition duration-200 ease-in-out transform "
            />
          </Link>
        )}

        <Link
          href={
            menuNavigationItem.link?.href ??
            menuNavigationItem.link?.slug ??
            '#'
          }
          className={`flex items-center justify-between py-2 hover:text-skin-primary ${
            menuNavigationItem.subItems &&
            menuNavigationItem.subItems.length > 0
              ? 'text-base font-medium'
              : ' '
          }`}
        >
          {menuNavigationItem.name}
        </Link>
        {hasSubMenu && (
          <SubMenu
            dept={dept}
            data={menuNavigationItem.subItems}
            menuIndex={menuIndex}
          />
        )}
      </li>
    );
  } else {
    const menuCategory = data as CategoryType;
    return (
      <li className="relative">
        {menuCategory?.image?.desktop.url && (
          <Link href={menuCategory.slug}>
            <Image
              src={
                menuCategory?.image?.desktop.url ??
                '/assets/placeholder/collection.svg'
              }
              alt={menuCategory.name}
              width={255}
              height={160}
              className="bg-sink-thumbnail object-cover transition duration-200 ease-in-out transform "
            />
          </Link>
        )}

        <Link
          href={menuCategory.slug}
          className={`flex items-center justify-between py-2 hover:text-skin-primary ${
            menuCategory.subCategories && menuCategory.subCategories.length > 0
              ? 'text-base font-medium'
              : ' '
          }`}
        >
          {menuCategory.name}
        </Link>
        {hasSubMenu && (
          <SubMenu
            dept={dept}
            data={menuCategory.subCategories}
            menuIndex={menuIndex}
          />
        )}
      </li>
    );
  }
};

const SubMenu: React.FC<any> = ({ dept, data, menuIndex }) => {
  dept = dept + 1;
  return (
    <ul className="subMenuChild  w-full py-1">
      {data?.map((menu: any, index: number) => {
        const menuName: string = `sidebar-submenu-${dept}-${menuIndex}-${index}`;
        return (
          <ListMenu
            dept={dept}
            data={menu}
            hasSubMenu={
              (menu.subItems && menu.subItems.length > 0) ??
              (menu.subCategories && menu.subCategories.length > 0)
            }
            menuName={menuName}
            key={menuName}
            menuIndex={index}
          />
        );
      })}
    </ul>
  );
};

export default ListMenu;
