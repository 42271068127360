import { Banner } from 'src/domain/cms/shared/common';

export function mapHomeHeroBanner(apiData: any): Banner[] {
  const result: Banner[] = new Array();
  const carouselTiles = apiData?.fields?.tiles ?? [];

  carouselTiles.forEach((tile: any) => {
    const desktopImage = tile.fields.image.fields.desktopImage.fields.file.url;
    const mobileImage = tile.fields.image.fields.mobileImage.fields.file.url;

    const banner: Banner = {
      id: tile.fields.id,
      title: tile.fields.title,
      subTitle: tile.fields?.subtitle ?? '',
      image: {
        desktop: {
          url: `https:${desktopImage}`,
        },
        mobile: {
          url: `https:${mobileImage}`,
        },
      },
      buttonText: tile.fields?.buttonText ?? '',
      buttonRef: tile.fields?.buttonRef ?? '',
    };
    result.push(banner);
  });

  return result;
}
