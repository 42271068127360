import { LOCALES } from '@utils/constants/locales';
import { CMSService } from 'src/domain/cms/cms-service';
import { CategoryService } from 'src/domain/commerce/category/category-service';

const getCMSHeader = async (
  service: CMSService,
  locale: string = LOCALES.DEFAULT,
) => {
  return await service.getHeader(locale);
};

const getCategoryMenu = async (service: CategoryService) => {
  return await service.getCategoryMenu();
};

export { getCMSHeader, getCategoryMenu };
