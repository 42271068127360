import Heading from '@components/ui/heading';
import Image from '@components/ui/image';
import Link from '@components/ui/link';
import { LinkProps } from 'next/link';
import Text from '@components/ui/text';
import { useTranslation } from 'next-i18next';
import { collectionPlaceholder } from '@assets/placeholders';
import { Blog } from '@framework/types';
import { formatDate } from '@utils/format-date';

interface Props {
  imgWidth?: number | `${number}`;
  imgHeight?: number | `${number}`;
  href: LinkProps['href'];
  collection: Blog;
}

const LatestblogCard: React.FC<Props> = ({
  collection,
  imgWidth = 440,
  imgHeight = 280,
  href,
}) => {
  const { imageUrl, title, date } = collection;

  const { t } = useTranslation('common');
  return (
    <Link href={href} className="group flex flex-col ">
      <Image
        src={imageUrl ?? collectionPlaceholder}
        alt={t('text-blog-thumbnail')}
        title={title}
        width={imgWidth}
        height={imgHeight}
        className="overflow-hidden  bg-skin-thumbnail object-cover transform transition duration-300 ease-in-out group-hover:opacity-90 "
      />
      <div className="flex flex-col mt-4 mb-6 ">
        <Heading
          variant="title"
          className="mb-1 lg:mb-1.5 text-base group-hover:text-skin-primary"
        >
          {title}
        </Heading>
        <Text className="sm:text-10px uppercase">
          {t('text-postdate')} <span> {formatDate(date)}</span>
        </Text>
      </div>
    </Link>
  );
};

export default LatestblogCard;
