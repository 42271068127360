import Container from '@components/ui/container';
import ContactForm from '../form/contact-form';
import Map from '@components/ui/map';
import Heading from '@components/ui/heading';
import Text from '@components/ui/text';
import LocationIcon from '@components/icons/contact/location-icon';
import PhoneIcon from '@components/icons/contact/phone-icon';
import MailIcon from '@components/icons/contact/mail-icon';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useTranslation } from 'next-i18next';

type ContactUsProps = {
  data: {
    fields: {
      title?: string;
      address?: string;
      phone?: string;
      email?: string;
      content?: any;
    };
  };
};

const ContactUsWrapper = ({ data }: ContactUsProps) => {
  const { t } = useTranslation('common');
  const contactUs = data?.fields ?? null;

  if (!contactUs) return null;

  return (
    <>
      <Container>
        <div className="flex flex-wrap bg-skin-fill w-full  xl:py-12  relative z-10">
          <div className="w-full md:w-[53%] xl:w-[60%] md:pe-8 lg:pe-0 2xl:pe-24 lg:mb-0 mb-8">
            <div className="flex flex-col lg:flex-row max-w-xs lg:max-w-sm xl:pe-7 mb-4">
              <div className="flex-shrink-0 w-14  h-14 border-2 p-3 rounded-md">
                <LocationIcon />
              </div>
              <div className="lg:ps-3 2xl:ps-4 mt-4 lg:mt-0">
                <Heading variant="base" className="">
                  {t('text-office-location')}
                </Heading>
                <Text>{contactUs?.address}</Text>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row max-w-xs lg:max-w-sm xl:pe-7  mb-4">
              <div className="flex-shrink-0 w-14  h-14 border-2 p-3 rounded-md">
                <PhoneIcon />
              </div>
              <div className="lg:ps-3 2xl:ps-4 mt-4 lg:mt-0">
                <Heading variant="base" className="">
                  {t('text-phone')}
                </Heading>
                <Text>{contactUs?.phone}</Text>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row max-w-xs lg:max-w-sm xl:pe-7  mb-4">
              <div className="flex-shrink-0 w-14  h-14 border-2 p-3 rounded-md">
                <MailIcon />
              </div>
              <div className="lg:ps-3 2xl:ps-4 mt-4 lg:mt-0">
                <Heading variant="base" className="">
                  {t('text-email')}
                </Heading>
                <Text>{contactUs?.email}</Text>
              </div>
            </div>
            <div
              className="text-sm sm:text-15px text-skin-base mb-10"
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(contactUs?.content),
              }}
            />
          </div>
          <div className="w-full md:w-[47%] xl:w-[40%] pb-0.5 lg:ps-12 pt-1.5">
            <ContactForm />
          </div>
        </div>
      </Container>
      <div className="mt-4 bg-skin-three relative h-[420px]">
        <Map
          lat={52.3446}
          lng={4.9124}
          height={'420px'}
          zoom={15}
          showInfoWindow={true}
        />
      </div>
    </>
  );
};

export default ContactUsWrapper;
