import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, next: any) =>
      `<p>${next(node.content)}</p>`,
    [BLOCKS.HEADING_1]: (node: any, next: any) =>
      `<h1 class="mb-3 text-2xl lg:text-4xl text-skin-base font-medium truncate">${next(
        node.content,
      )}</h1>`,
    [BLOCKS.HEADING_2]: (node: any, next: any) =>
      `<h2 class="mb-3 text-[20px] text-skin-base font-medium truncate">${next(
        node.content,
      )}</h2>`,
    [BLOCKS.HEADING_3]: (node: any, next: any) =>
      `<h3 class="mb-3 text-[20px] text-skin-base font-medium truncate">${next(
        node.content,
      )}</h3>`,
    [BLOCKS.HEADING_4]: (node: any, next: any) =>
      `<h4 class="mb-3 text-[20px] text-skin-base font-medium truncate">${next(
        node.content,
      )}</h4>`,
    [BLOCKS.HEADING_5]: (node: any, next: any) =>
      `<h5 class="mb-3 text-[20px] text-skin-base font-medium truncate">${next(
        node.content,
      )}</h5>`,
    [BLOCKS.HEADING_6]: (node: any, next: any) =>
      `<h6 class="mb-3 text-[20px] text-skin-base font-medium truncate">${next(
        node.content,
      )}</h6>`,
    [BLOCKS.QUOTE]: (node: any, next: any) =>
      `<blockquote class="lg:max-w-[80%] my-10 mx-auto rounded-xl text-gray-500 bg-[#f4f6fa] text-[22px] px-8 lg:px-12 py-8">${next(
        node.content,
      )}</blockquote>`,
  },
};

type FreeStyleHTMLProps = {
  data: {
    fields: {
      content: any;
      margin?: number;
    };
  };
};

const FreeStyleHTML = ({ data }: FreeStyleHTMLProps) => {
  const html = data?.fields?.content ?? null;
  const margin = data?.fields?.margin ?? 0;

  if (!html) return null;

  return (
    <div
      className={`content text-sm sm:text-15px text-skin-base mb-10 xl:mx-${margin}`}
      dangerouslySetInnerHTML={{
        __html: documentToHtmlString(html, options),
      }}
    />
  );
};

export default FreeStyleHTML;
