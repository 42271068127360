import { useInfiniteHits } from 'react-instantsearch';
import { AlogliaStateEnum } from '@utils/constants';
import ProductCard from '@components/product/product-cards/product-card';
import ProductList from '@components/product/product-cards/product-list-view';
import { HitProps, formatProductData } from '@utils/format-product-data';
import Button from '@components/ui/button';

type AlgoliaItemListProps = {
  status: any;
  viewAs?: boolean;
};

const AlgoliaItemList = ({ status, viewAs = true }: AlgoliaItemListProps) => {
  const { hits, isLastPage, showMore } = useInfiniteHits();

  if (hits.length === 0 && status === AlogliaStateEnum.Idle) {
    return <h2>No result found</h2>;
  }

  return (
    <>
      <div
        className={`${
          viewAs
            ? 'grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
            : 'grid grid-cols-1 gap-8'
        }`}
      >
        {viewAs
          ? hits?.map((hit) => {
              const product = formatProductData(hit as unknown as HitProps);
              return (
                <ProductCard
                  key={`product--key-${product.id}`}
                  product={product}
                />
              );
            })
          : hits?.map((hit) => {
              const product = formatProductData(hit as unknown as HitProps);
              return (
                <ProductList
                  key={`product--key-${product.id}`}
                  product={product}
                />
              );
            })}
      </div>
      {!isLastPage && (
        <div className="text-center pt-8 xl:pt-10">
          <Button onClick={showMore} className={'w-60 '}>
            Load More
          </Button>
        </div>
      )}
    </>
  );
};

export default AlgoliaItemList;
