import SectionHeader from '@components/common/section-header';
import CategoryListCardLoader from '@components/ui/loaders/category-list-card-loader';
import Alert from '@components/ui/alert';
import CategoryListCard from '@components/cards/category-list-card';
import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { ShopByCategories } from 'src/domain/cms/home/shop-by-category';

interface CategoriesProps {
  data?: ShopByCategories;
  isLoading?: boolean;
  error?: Error | null;
  className?: string;
}

const breakpoints = {
  '1480': {
    slidesPerView: 6,
  },
  '1280': {
    slidesPerView: 5,
  },
  '1024': {
    slidesPerView: 3,
  },
  '768': {
    slidesPerView: 3,
  },
  '600': {
    slidesPerView: 3,
  },
  '0': {
    slidesPerView: 2,
  },
};

const CategoryGridListBlock: React.FC<CategoriesProps> = ({
  data,
  isLoading,
  error,
  className = 'mb-8',
}) => {
  const CATEGORIES_LIMITS = 6;
  return (
    <div className={cn(className)}>
      <SectionHeader
        sectionHeading={data?.title ?? ''}
        className="mb-6 block-title"
      />

      <div className="mt-0">
        {error ? (
          <Alert message={error?.message} />
        ) : (
          <Carousel
            breakpoints={breakpoints}
            grid={{ rows: 1, fill: 'row' }}
            className=""
          >
            {isLoading && !data
              ? Array.from({ length: 6 }).map((_, idx) => {
                  return (
                    <SwiperSlide
                      className="p-1.5 md:p-2"
                      key={`category--key-${idx}`}
                    >
                      <CategoryListCardLoader
                        uniqueKey={`category-card-${idx}`}
                      />
                    </SwiperSlide>
                  );
                })
              : data?.categories
                  ?.slice(0, CATEGORIES_LIMITS)
                  .map((category, index) => (
                    <SwiperSlide key={`category--key-${index}`} className="">
                      <CategoryListCard
                        category={category}
                        href={category?.slug}
                        className=""
                      />
                    </SwiperSlide>
                  ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default CategoryGridListBlock;
