import { ContentfulContentTypes } from '@utils/constants';
import { CommerceProductCarousel } from 'src/domain/cms/product/types';

export function mapProductCarousel(apiData: any): CommerceProductCarousel {
  const { fields, sys } = apiData;
  const contentType = sys?.contentType ?? {};
  const { id } = contentType?.sys ?? {};
  if (
    id.toLowerCase() ===
    ContentfulContentTypes.ProductCarouselWrapper.toLowerCase()
  ) {
    return fields;
  } else {
    return { title: fields?.title, errorMessage: 'Content Type Mismatch' };
  }
}
