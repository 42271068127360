import HeroBannerCard from '@components/hero/hero-banner-card';
import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from '@components/ui/carousel/slider';
import Alert from '@components/ui/alert';
import { Banner } from 'src/domain/cms/shared/common';

interface Props {
  data?: Banner[];
  isLoading?: boolean;
  error?: Error | null;
  className?: string;
  contentClassName?: string;
  heroContent?: boolean;
}

const HeroTwoSliderBlock: React.FC<Props> = ({
  data = [],
  isLoading = true,
  error = null,
  className = 'mb-7',
  contentClassName = 'py-24',
  heroContent = true,
}) => {
  return (
    <div className={`${className}`}>
      {error ? (
        <div className="2xl:pe-10">
          <Alert message={error?.message} />
        </div>
      ) : (
        <div>
          {isLoading ? (
            <p>loading...</p>
          ) : (
            <Carousel
              pagination={{
                clickable: true,
              }}
              navigation={true}
              autoplay={true}
              prevActivateId={`prevActivateId`}
              nextActivateId={`nextActivateId`}
              className={'overflow-hidden'}
            >
              {data?.map((banner: Banner) => (
                <SwiperSlide key={`banner--key${banner.id}`}>
                  <HeroBannerCard
                    banner={banner}
                    variant="slider"
                    heroContentCard={heroContent}
                    className={contentClassName}
                  />
                </SwiperSlide>
              ))}
            </Carousel>
          )}
        </div>
      )}
    </div>
  );
};

export default HeroTwoSliderBlock;
