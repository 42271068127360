import FeaturedCard from '@components/cards/featured-card';
import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from '@components/ui/carousel/slider';
import { UspBlock } from 'src/domain/cms/home/usp-block';

interface Props {
  className?: string;
  classNameCarousel?: string;
  data?: UspBlock[];
}

const breakpoints = {
  '1536': {
    slidesPerView: 5,
  },
  '1280': {
    slidesPerView: 4,
  },
  '1024': {
    slidesPerView: 3,
  },
  '768': {
    slidesPerView: 2,
  },
  '640 ': {
    slidesPerView: 2,
  },
  '0': {
    slidesPerView: 1,
  },
};

const FeatureCarousel: React.FC<Props> = ({
  className = 'mb-7 md:mb-10 ',
  classNameCarousel,
  data = [],
}) => {
  return (
    <div className={`mb-7 md:mb-10 ${className}`}>
      <Carousel
        autoplay={false}
        breakpoints={breakpoints}
        prevActivateId="featured-carousel-button-prev"
        nextActivateId="featured-carousel-button-next"
        prevButtonClassName="start-3  3xl:top-auto 3xl:-translate-y-2 4xl:-translate-y-10"
        nextButtonClassName={`end-3  3xl:top-auto transform 2xl:translate-x-0 3xl:-translate-y-2 `}
        className={`rounded-md border border-black/10 py-6  ${classNameCarousel}`}
      >
        {data?.map((item: UspBlock) => (
          <SwiperSlide key={`featured-key-${item.title}`}>
            <FeaturedCard item={item} />
          </SwiperSlide>
        ))}
      </Carousel>
    </div>
  );
};

export default FeatureCarousel;
