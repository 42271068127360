import { ComponentType } from '@utils/getComponentProps';
import { mapHomePage } from '../contentful-mapper';
import productController from 'src/controller/product-controller';
import { getAppConfig } from '@utils/get-app-config';

const config = getAppConfig();

const getProducts = async (fields: any, algoliaFetchDisable: boolean) => {
  let data: any[] = [];
  try {
    data = await productController.GetProducts.fetch(
      fields,
      algoliaFetchDisable,
    );
  } catch (err) {
    console.log(err);
  }

  return data;
};

async function homePageMapper(result: any) {
  const items = (result?.fields?.contentItems as any[]) ?? [];
  for (let item of items) {
    const type = item?.sys?.contentType?.sys?.id;
    if (type === ComponentType.BestSellers && item?.fields?.skuList) {
      item.fields.products = await getProducts(
        item.fields,
        config.DISABLE_ALGOLIA_FETCH === 'true',
      );
    }

    if (
      (type === ComponentType.Digital || type === ComponentType.Fashion) &&
      item?.fields?.productsList?.fields?.skuList
    ) {
      item.fields.productsList.fields.products = await getProducts(
        item.fields.productsList.fields,
        config.DISABLE_ALGOLIA_FETCH === 'true',
      );
    }

    if (type === ComponentType.CategoryProducts) {
      try {
        const data = await productController.GetProducts.fetch(
          item.fields.productsList.fields,
          config.DISABLE_ALGOLIA_FETCH === 'true',
        );
        item.fields.productsList.fields.products = data;
      } catch (err) {
        console.log(err);
      }
    }
  }
  return mapHomePage(result);
}

export default homePageMapper;
