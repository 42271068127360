import { useState } from 'react';
import { DynamicWidgets, useInstantSearch } from 'react-instantsearch';
import AlgoliaItemList from '@components/algolia/algolia-item-list';
import AlgoliaTopBar from '@components/algolia/algolia-top-bar';
import FallbackComponent from '@components/algolia/fallback-component';
import AlgoliaStats from '../algolia-stats';

type AlogliaProductsProps = {
  showHeader?: boolean;
  showSidebar?: boolean;
  categoryName?: string;
};

const AlogliaProducts = ({
  showHeader = true,
  showSidebar = true,
  categoryName,
}: AlogliaProductsProps) => {
  const [viewAs, setViewAs] = useState<boolean>(true);
  const { status, results } = useInstantSearch();
  const hasResults = Boolean(results?.nbHits ?? 0);

  return (
    <div className="flex">
      {showHeader && hasResults && (
        <div className="flex-shrink-0 pe-8 xl:pe-16 hidden lg:block w-80 xl:w-86 sticky top-0 h-full">
          <div className="space-y-10">
            <DynamicWidgets fallbackComponent={FallbackComponent} />
          </div>
        </div>
      )}
      <div className="w-full">
        {showSidebar && hasResults && (
          <>
            <AlgoliaStats categoryName={categoryName} />
            <AlgoliaTopBar viewAs={viewAs} onNavClick={setViewAs} />
          </>
        )}

        <AlgoliaItemList status={status} viewAs={viewAs} />
      </div>
    </div>
  );
};
export default AlogliaProducts;
