import Heading from '@components/ui/heading';
import Link from '@components/ui/link';
import cn from 'classnames';
import { LinkProps } from 'next/link';
import Text from '@components/ui/text';
import { useRouter } from 'next/router';
import { getDirection } from '@utils/get-direction';
import Image from '@components/ui/image';
interface ItemProps {
  title: string;
  href: LinkProps['href'];
  subTitle?: string;
  image?: string;
  icon?: JSX.Element;
  description?: string;
}

interface Props {
  className?: string;
  layout?: string;
  item: ItemProps;
}

const FeaturedCard: React.FC<Props> = ({ item, className, layout }) => {
  const { image, title, href, subTitle, icon } = item;

  const { locale } = useRouter();
  const dir = getDirection(locale);
  const borderRTL = dir == 'rtl' ? 'border-l' : 'border-r';

  const renderImage = () => {
    if (image) {
      const src: string = image.startsWith('http') ? image : `https:${image}`;
      return (
        <Image
          src={src}
          alt={title}
          width={42}
          height={42}
          className="w-full"
        />
      );
    }

    return icon;
  };

  return (
    <Link href={href} className="w-full">
      <div
        className={cn(
          'group px-5 xl:px-5 2xl:px-8  flex items-center justify-center  border-black/10',
          borderRTL,
          className,
        )}
      >
        <div className="flex flex-shrink-0 items-center justify-center">
          {renderImage()}
        </div>

        {layout == 'home4' ? (
          <div className="ps-4">
            <Heading
              variant="base"
              className="sm:text-sm hover:text-skin-primary"
            >
              {title}
            </Heading>
            <Text className={'text-13px lg:leading-[22px]'}>{subTitle}</Text>
          </div>
        ) : (
          <div className="ps-4">
            <Heading variant="base" className="hover:text-skin-primary">
              {title}
            </Heading>
            <Text> {subTitle}</Text>
          </div>
        )}
      </div>
    </Link>
  );
};

export default FeaturedCard;
