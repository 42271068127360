import { QueryOptionsType } from '@framework/types';
import { IntershopCategoryGateway } from '../gateway/commerce/ish-category-gateway';
import { getCMSHeader, getCategoryMenu } from '../use-cases/get-header-content';
import { useQuery } from '@tanstack/react-query';
import { ContentfulGateway } from 'src/gateway/cms/contentful-gateway';
import { Header } from 'src/domain/cms/header/header';
import { ISHCategoryType } from 'src/domain/commerce/category/category-menu';

const headerController = () => {
  const fetchCMSHeader = async ({ queryKey }: any) => {
    const [_key, _params] = queryKey;
    const { locale } = _params;
    return await getCMSHeader(ContentfulGateway(), locale);
  };

  const useCMSHeader = (options: QueryOptionsType) => {
    return useQuery<Header, Error>(['cmsHeader', options], fetchCMSHeader, {
      enabled: options.enabled,
    });
  };

  const fetchCategoryMenu = async ({ queryKey }: any) => {
    const [_key, _params] = queryKey;
    return await getCategoryMenu(IntershopCategoryGateway());
  };

  const useCategoryMenu = (options: QueryOptionsType) => {
    return useQuery<ISHCategoryType[], Error>(
      ['categoryMenu', options],
      fetchCategoryMenu,
      { enabled: options.enabled },
    );
  };

  return {
    cmsHeader: {
      fetch: fetchCMSHeader,
      use: useCMSHeader,
    },
    categoryMenu: {
      fetch: fetchCategoryMenu,
      use: useCategoryMenu,
    },
  };
};

export default headerController();
