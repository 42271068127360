import { UspBlock } from 'src/domain/cms/home/usp-block';

export function mapHomeUSP(apiData: any): UspBlock[] {
  const result: UspBlock[] = new Array();

  const usbResult = apiData?.fields?.items ?? [];
  usbResult.forEach((element: any) => {
    const usbContent: UspBlock = {
      title: element.fields.title,
      subTitle: element.fields?.subTitle ?? '',
      image: element.fields?.image?.fields?.file?.url ?? '',
      href: element.fields?.url ?? '',
    };
    result.push(usbContent);
  });

  return result;
}
