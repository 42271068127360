import { useStats } from 'react-instantsearch';
import { formatCategoryName } from '@utils/format-string';
import Heading from '@components/ui/heading';

const AlgoliaStats = (props: any) => {
  const { query, nbHits } = useStats();
  if (query === undefined || query === '') {
    return (
      <Heading className="mb-5 md:mb-10 text-base text-[26px] xl:text-[30px]">
        Category &quot;
        {formatCategoryName(props.categoryName)}
        &quot; resulted in {nbHits} hits
      </Heading>
    );
  }
  return (
    <Heading className="mb-5 md:mb-10 text-base text-[26px] xl:text-[30px]">
      Searching for &quot;{query}
      &quot; resulted in {nbHits} hits
    </Heading>
  );
};

export default AlgoliaStats;
