export const contentfulSettings = {
  development: {
    homePageId: '18L2u6vBmnqNsQgJ5ZH2hl',
    headerId: '204XxYVIySxrNytnAt6k9X',
    defaultSearchContentId: '5R05MuUdMyhw5tNHmdKH2G',
    footerId: 'WXpsdaTuyjYKgeIHDHhhh',
    productPageId: '6JZGKxx4EEkN6d7orne3dr',
  },
  test: {
    homePageId: '18L2u6vBmnqNsQgJ5ZH2hl',
    headerId: '204XxYVIySxrNytnAt6k9X',
    defaultSearchContentId: '5R05MuUdMyhw5tNHmdKH2G',
    footerId: 'WXpsdaTuyjYKgeIHDHhhh',
    productPageId: '6JZGKxx4EEkN6d7orne3dr',
  },
  production: {
    homePageId: '18L2u6vBmnqNsQgJ5ZH2hl',
    headerId: '204XxYVIySxrNytnAt6k9X',
    defaultSearchContentId: '5R05MuUdMyhw5tNHmdKH2G',
    footerId: 'WXpsdaTuyjYKgeIHDHhhh',
    productPageId: '6JZGKxx4EEkN6d7orne3dr',
  },
};
