import { FooterMenuLinks } from 'src/domain/cms/footer/footer';

export const getFooterMenuLinks = (apiData: any): FooterMenuLinks => {
  return apiData?.map((item: any, itemIndex: number) => {
    const widgetTitle = item?.fields?.footerMenuTitle ?? '';
    const menus = item?.fields?.footerMenu ?? [];
    const lists = menus?.map((menu: any, menuIndex: number) => {
      return {
        id: menuIndex + 1,
        title: menu?.fields?.title ?? '',
        path: menu?.fields?.path ?? '',
      };
    });

    return {
      id: itemIndex + 1,
      widgetTitle,
      lists,
    };
  });
};
