export const getHelpTextData = (helpLineData: any) => {
  const helpLineLogoObj = helpLineData?.fields?.helpLineLogo;
  const helpLineLogo = {
    title: helpLineLogoObj?.fields?.title ?? '',
    height: helpLineLogoObj?.fields?.file?.details?.image?.height,
    width: helpLineLogoObj?.fields?.file?.details?.image?.width,
    url: helpLineLogoObj?.fields?.file?.url
      ? `https:${helpLineLogoObj.fields.file.url}`
      : '',
  };
  const helpLineText = helpLineData?.fields?.helpLineTextBlock;
  const helpLineNumber = helpLineData?.fields.helpLineNumber;
  const email = helpLineData?.fields.email;
  const address = helpLineData?.fields.address;
  const social = helpLineData?.fields?.icons?.map(
    (item: any, index: number) => ({
      id: index + 1,
      path: item?.field?.link,
      title: item?.fields?.title ?? '',
      height: item?.fields?.file?.details?.image?.height,
      width: item?.fields?.file?.details?.image?.width,
      image: item?.fields?.file?.url ? `https:${item.fields.file.url}` : '',
    }),
  );
  return {
    helpLineLogo,
    helpLineText,
    helpLineNumber,
    email,
    social,
    address,
  };
};
