import { AlgoliaProductGateway } from 'src/gateway/algolia/algolia-product-gateway';
import { AlgoliaRecommendGateway } from '../gateway/algolia/algolia-recommend-gateway';
import { getProductRecommendations } from '../use-cases/get-product-recommendations';
import {
  getAlgoliaProductByCategory,
  getAlgoliaProductBySlug,
  getAlgoliaProductsBySku,
} from 'src/use-cases/get-algolia-products';
import { GetProductsByCategoryParams, Product } from '@framework/types';
import { useQuery } from '@tanstack/react-query';
import { getIntershopProductsBySku } from 'src/use-cases/get-ish-products';
import { IntershopProductGateway } from 'src/gateway/commerce/ish-product-gateway';

const productController = () => {
  const fetchProductPageData = async (id: string) => {
    try {
      return await getProductRecommendations(AlgoliaRecommendGateway(), id);
    } catch (err) {
      console.error((err as Error).message);
      return [];
    }
  };

  const fetchProductBySlug = async (slug: string) => {
    try {
      return await getAlgoliaProductBySlug(AlgoliaProductGateway(), slug);
    } catch (err) {
      console.error((err as Error).message);
      return null;
    }
  };

  const fetchProductsViaAlgolia = async ({ queryKey }: any) => {
    const [_key, _params] = queryKey;
    const { skuList } = _params;
    return await getAlgoliaProductsBySku(AlgoliaProductGateway(), skuList);
  };

  const fetchCommerceProducts = async ({ queryKey }: any) => {
    const [_key, _params] = queryKey;
    const { skuList } = _params;
    return await getIntershopProductsBySku(IntershopProductGateway(), skuList);
  };

  const useCommerceProductsQuery = (
    options: any,
    algoliaFetchDisable: boolean,
  ) => {
    const key = algoliaFetchDisable
      ? 'Products-Via-Intershop'
      : 'Products-via-Algolia';
    return useQuery<Product[], Error>(
      [key, options],
      algoliaFetchDisable ? fetchCommerceProducts : fetchProductsViaAlgolia,
    );
  };

  const fetchProducts = async (_params: any, algoliaFetchDisable: boolean) => {
    const _key = algoliaFetchDisable
      ? 'Products-Via-Intershop'
      : 'Products-via-Algolia';
    const params = { queryKey: [_key, _params] };
    const func = algoliaFetchDisable
      ? fetchCommerceProducts
      : fetchProductsViaAlgolia;
    return await func(params);
  };

  const fetchProductByCategory = async ({ queryKey }: any) => {
    const [_key, _params] = queryKey;
    const { category, hitsPerPage = 20 } = _params;
    try {
      return await getAlgoliaProductByCategory(AlgoliaProductGateway(), {
        category,
        hitsPerPage,
      });
    } catch (err) {
      console.error((err as Error).message);
      return null;
    }
  };

  const useProductByCategory = (options: GetProductsByCategoryParams) => {
    return useQuery<Product[] | null, Error>(
      ['Algolia-products-via-category', options],
      fetchProductByCategory,
      { enabled: options.enabled },
    );
  };

  return {
    ProductPageContent: {
      fetch: fetchProductPageData,
    },
    ProductBySlug: {
      fetch: fetchProductBySlug,
    },
    ProductByCategory: {
      fetch: fetchProductByCategory,
      use: useProductByCategory,
    },
    GetProducts: {
      fetch: fetchProducts,
    },
    useCommerceProductsQuery,
  };
};

export default productController();
