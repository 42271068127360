import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Logo from '@components/ui/logo';
import Image from '@components/ui/image';
import { useRouter } from 'next/router';
import { getDirection } from '@utils/get-direction';
import headerController from 'src/controller/header-controller';
import React from 'react';
interface AboutProps {
  className?: string;
  helpLineData: {
    contact: string;
    helpLineLogo: any;
    helpLineText: string;
    helpLineNumber: string;
    address: string;
    email: string;
    url?: string;
    social?: {
      id: string | number;
      path?: string;
      name: string;
      image: string;
      width: number;
      height: number;
      url?: string;
      title?: string;
    }[];
  };
}
type ImageContainerProps = { children: React.ReactNode; item: any };
const ImageContainer = ({ children, item }: ImageContainerProps) => {
  if (item.path) {
    return (
      <Link href={item.path} target="_blank" rel="noreferrer">
        {children}
      </Link>
    );
  }
  return children;
};
const WidgetAbout: React.FC<AboutProps> = ({ helpLineData, className }) => {
  const headerCMSContent = headerController.cmsHeader.use({});
  const { t } = useTranslation('footer');
  const { locale } = useRouter();
  const dir = getDirection(locale);
  const { helpLineLogo, helpLineText, social, helpLineNumber, address, email } =
    helpLineData;
  return (
    <div className={`${className}`}>
      <div className="text-sm max-w-[350px] mx-auto sm:ms-0 pb-2">
        <Logo
          image={headerCMSContent.data?.logo}
          isLoading={headerCMSContent.isLoading}
          className="lg:mb-6"
        />
        <div
          className={`bg-iconPhone bg-no-repeat  min-h-[60px] mb-5 ${
            dir === 'rtl' ? 'pr-16 xl:pr-20 bg-right' : 'pl-16 xl:pl-20'
          }`}
          style={{ background: `url('${helpLineLogo?.url}') no-repeat` }}
        >
          <p className="text-white">{helpLineText ?? t('text-hotline')}</p>
          <div className="text-skin-primary text-lg">{helpLineNumber}</div>
        </div>
        <div className="mb-3">{address}</div>
        <div className="mb-3">{email}</div>
      </div>

      {social && (
        <ul className="flex flex-wrap  space-s-4 md:space-s-5 mx-auto md:mx-0">
          {social?.map((item) => (
            <li
              className="transition hover:opacity-80"
              key={`social-list--key${item.id}`}
            >
              <ImageContainer item={item}>
                <Image
                  src={item.image}
                  alt={item.title ?? ''}
                  height={item.height}
                  width={item.width}
                  className="transform scale-85 md:scale-100"
                />
              </ImageContainer>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default WidgetAbout;
